import React from "react";
import {withTranslation} from "react-i18next";
import {getDateDisplay} from "../../../../utility/dateUtils";
import Loading from "../../../results/loading";
import Random from "../../../results/random";
import {SCPostCode} from "../../../../constant/General";

const ResultShowSc = (props) => {
    const {result, t} = props;

    return <div id="result-show" className="animate-slide-up">
        <div className="card-product prod-width p-0">
            <div className="row col-sm-12 m-0 p-0">
                <div className="col-sm-12 bg-c-blue text-center text-yellow py-2" align="left">
                    <span
                        className="fw-bold head-result-date">{getDateDisplay(result.drawDate)} {t("labels:shift")} : {result.drawTime}</span>
                </div>
            </div>
            <hr className="m-0 border-dark"/>
            <div className="text-center p-lr-vw">
                {
                    result?.results?.map((e, i)=>{
                        const postNumber = e?.number
                        const renderResult = () => {
                            if (postNumber.includes("*")) {
                                return <span className=""><Loading sizeDigit={postNumber.length} className="loading-draw w-1-vw"/></span>
                            } else if (postNumber.includes("?")) {
                                return <span className="text-post-sc"><Random sizeDigit={postNumber.length}
                                                                                          className="draw-style-a mx-1"/></span>
                            } else return <span
                                className="number-sc"
                                id="jack-port"
                                data-num={postNumber}>{postNumber}</span>
                        };

                        return(
                                <div key={i} className="row m-3 rounded-sc bg-sc">
                                    <div className="col-2 text-center bg-danger d-flex justify-content-center align-items-center post-rounded-sc">
                                        <span className="post-code-sc">{SCPostCode[e.postCode]}</span>
                                    </div>
                                    <div className="col-10 text-center d-flex justify-content-center align-items-center">
                                        {renderResult()}
                                    </div>
                                </div>
                        );
                    })
                }

            </div>
        </div>
    </div>
};

export default (withTranslation()(ResultShowSc));
