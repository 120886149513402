import React, {useState} from "react";
import {digits, onChangeDigits, onMouseEnter, onMouseLeave} from "../../../utility/General";
import {withTranslation} from "react-i18next";
import Active from "../../results/active";
import Waiting from "../../results/wating";
import {getDate} from "../../../utility/dateUtils";


const ResultShow = ({t, ...props}) => {
    const {i18n, result, type} = props;
    const [activeDigit, setActiveDigit] = useState(0);
    const isKm = i18n.language === "km";
    const waiting = result.status === "WAITING";

    const renderImg = (sizeDigit, className) => {
        if (waiting) {
            return <Waiting sizeDigit={sizeDigit} className={className}/>
        } else return <Active sizeDigit={sizeDigit} className={className}/>
    };

    return <div className="animate-slide-up">
        <div id="result-show" className={`lottery-${result.status}`}>
            <div className="card-product prod-width p-2">
                <div className="row col-sm-12">
                    <div className="col-sm-8 mt-3 mb-3" align="left">
                        <span className="fw-bold head-result-date">{getDate(result.drawDate)}{t("labels:shift")} : {result.drawTime}</span>
                    </div>
                </div>
                <hr className="m-1 border-dark"/>
                <div className="row post-a" id="post-a">
                    <div className="col-2 card-border-right text-center post-a-vertical-middle py-5">
                        <p className={`align-middle text-blue-c ${isKm ? "f-result" : "f-result-en"}`}>{t("labels:grand_price")}</p>
                    </div>
                    <div className="col-10 text-center p-post-a big-result-loading align-self-center">
                    <span>
                        {
                            renderImg(type, "img-waiting-A")
                        }
                    </span>
                    </div>
                </div>
                <hr className="m-1 border-dark"/>
                <div className="row p-0">
                    <div className="col-2 card-border-right text-center lo-vertical-middle">
                        <p className={isKm ? "f-result" : "f-result-en"}>{t("labels:reward_consolation")}<br/>{t("labels:consolation")}
                        </p>
                    </div>
                    <div className="col-10 mt-3 row">
                        {
                            result?.results[1].map((item, i) => {
                                return <div key={i} className="col-sm-3 text-center p-0">
                                <span className="result-highlight lo-result" key={i}>
                                    {
                                        renderImg(5, "img-waiting-lo")
                                    }
                                </span>
                                </div>
                            })
                        }
                    </div>
                </div>
                <hr className="m-1 border-dark"/>
                <div className="row post-special d-flex" id="post-spec">
                    <div className="col-2 card-border-right text-center post-vertical-middle pr-0">
                        <p className={`text-blue-c ${isKm ? "f-result" : "f-result-en"}`}>{t("labels:reward_spacial")}<br/>{t("labels:spacial")}
                        </p>
                    </div>
                    <div className="col-10 mt-2 mb-0">
                        <div className="row mr-1">
                            {
                                ["B", "F", "C", "I", "D", "N"].map((item, i) => {
                                    const renderPostNum = () => {
                                        return <span className="align-middle" key={i}>
                                        {
                                            renderImg(type, "img-waiting-post")
                                        }
                                    </span>

                                    };
                                    return (
                                        <div key={i}
                                             className={`col-6 p-0 text-center mb-2 pr-0 ${i < 4 ? "card-border-bottom" : ""}`}>
                                            <div
                                                className={`text-center mb-4 mt-3 spacial-result-loading ${i % 2 !== 1 ? "card-border-right" : ""}`}>
                                                {
                                                    renderPostNum()
                                                }
                                            </div>
                                        </div>)
                                })
                            }
                        </div>
                    </div>

                </div>
            </div>
            {/*Search Result*/}
            <div className="card-product boxBottom row p-0 m-0 bg-white">
                <div className="p-2 filter-balls">
                    {
                        digits.map((e, i) => {
                            let active = e.value === activeDigit ? " active-digit" : "";
                            return <span key={i}
                                         onClick={_ => onChangeDigits(e.value, activeDigit, setActiveDigit)}
                                         className={`filter-digits fw-digit ${active}`}>{t(`labels:${e.label}`)}</span>
                        })
                    }
                </div>
                {
                    Array(10).fill(0).map((_, i) => {
                        return <div key={i}
                                    className="ball-num"
                                    onMouseEnter={() => onMouseEnter(i, activeDigit)}
                                    onMouseLeave={() => onMouseLeave(activeDigit)}
                        >
                            <span className="p-0">{i}</span>
                        </div>
                    })
                }

            </div>
        </div>
    </div>
};

export default (withTranslation()(ResultShow));
