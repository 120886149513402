import MainTable from "../../../components/mainpage/mainTable";
import React, {useEffect} from "react";
import {withTranslation} from "react-i18next";
import PrintA4 from "../../../components/results/prints/6D/printA4";
import {connect} from "react-redux";
import {fetchResults} from "../../../redux/actions/resultActions";
import SixDPrint from "../../../components/results/prints/6D/6DPrint";


const Print6DResults = ({...props}) => {
    const {fetchResults, match:{params:{date}}, results:{list}} = props;

    useEffect(() => {
        fetchResults({filterByDate:date})
    }, [fetchResults, date]);

    return <MainTable>
        <div className="mt-3">
            <PrintA4 list={list} {...props}/>
            <hr/>
            <SixDPrint list={list} {...props}/>
        </div>
    </MainTable>
};
const mapStateToProps = state => ({
    results: state.result.result,
});

export default connect(
    mapStateToProps, {
        fetchResults
    }
)(withTranslation()(Print6DResults));