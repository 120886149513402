import axios from "axios";
import {socket} from "../../constant/General";
import {DRAW_RESULT5, DRAW_RESULT6, FETCH_RESULT} from "../reducers/result/types";
import {DRAW_RESULT_MT, FETCH_RESULT_MT, LIST_RESULT_MT} from "../reducers/resultMt/types";
import {DRAW_RESULT_TN, FETCH_RESULT_TN, LIST_RESULT_TN} from "../reducers/resultTn/types";
import {LIST_DRAW_STATUS} from "../reducers/drawStatus/types";
import {DRAW_RESULT_VN1, FETCH_RESULT_VN1, LIST_RESULT_VN1} from "../reducers/resultVn1/types";
import {DRAW_RESULT_SC, FETCH_RESULT_SC, LIST_RESULT_SC} from "../reducers/resultSc/types";

const basic = process.env.REACT_APP_API_BASE_URL;

export const fetchResults = (params = {}) => dispatch => {
    dispatch({type: FETCH_RESULT});
    return axios.get(basic + "api/v1.0.0/all/result", {params: params}).then(response => {
        let data = response.data.data;
        if (data) {
            Object.entries(data).forEach((e) => {
                e && dispatch({
                    type: e[0],
                    payload: {
                        data: e[1]
                    }
                })
            })
        }
        return response;
    });
};

export const fetchDrawStatus = (params = {}) => dispatch => {
    return axios.get(basic + "api/v1.0.0/all/draw-status").then(response => {
        let data = response.data.data;
        if (data) {
            dispatch({
                type: LIST_DRAW_STATUS,
                payload: data
            })
        }
        return response;
    });
};

export const fetchResultByType = (params = {}, type = "mt") => dispatch => {
    let fetch;
    let list;
    switch (type) {
        case "mt":
            fetch = FETCH_RESULT_MT;
            list = LIST_RESULT_MT;
            break;
        case "vn1":
            fetch = FETCH_RESULT_VN1;
            list = LIST_RESULT_VN1;
            break;
        case "tn":
            fetch = FETCH_RESULT_TN;
            list = LIST_RESULT_TN;
            break;
        case "sc":
            fetch = FETCH_RESULT_SC;
            list = LIST_RESULT_SC;
            break;
        default:
    }
    dispatch({type: fetch});
    return axios.get(basic + `api/v1.0.0/${type}/result`, {params: params}).then(response => {
        dispatch({
            type: list,
            payload: response.data
        });
        return response;
    });
};

export const resultSocket = (callback) => dispatch => {

    socket.on('connect', () => {
        // console.log("----->connect");
    });

    socket.on('disconnect', () => {
        // console.log("----->disconnect");
    });

    socket.on('leap6d_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT6,
            payload: e
        });
    });
    socket.on('leap5d_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT5,
            payload: e
        });
    });
    socket.on('mt_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT_MT,
            payload: e
        });
    });
    socket.on('tn_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT_TN,
            payload: e
        });
    });
    socket.on('vnone_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT_VN1,
            payload: e
        });
    });

    socket.on('sc_result', (e) => {
        callback && callback();
        dispatch({
            type: DRAW_RESULT_SC,
            payload: e
        });
    });
};


