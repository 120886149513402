import React from "react"
import Header from "./header/header";
import Footer from "./footer/footer";

const MainTable = (props) => {
    const {children} = props;
    return (
        <div className="d-flex flex-column h-100">
            <main className="flex-shrink-0">
                <div className="container-fluid position-relative">
                    <Header {...props}/>
                    {children}
                </div>
            </main>
            <Footer/>
        </div>
    )
};

export default MainTable
