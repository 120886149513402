import {combineReducers} from "redux";
import resultReducer from "./result";
import resultMtReducer from "./resultMt";
import resultTnReducer from "./resultTn";
import resultVn1Reducer from "./resultVn1";
import drawStatus from "./drawStatus/draw";
import resultScReducer from "./resultSc";


const rootReducer = combineReducers({
    result: resultReducer,
    resultMt: resultMtReducer,
    resultTn: resultTnReducer,
    resultVn1: resultVn1Reducer,
    resultSc: resultScReducer,
    drawStatus:drawStatus
});

export default rootReducer;
