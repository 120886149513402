import React from 'react';
import {getDateDisplay, getTimeN} from "../../../utility/dateUtils";
import Loading from "../loading";
import Random from "../random";

const NightFormMC = ({items, t, isQr = false, id, isLive = false}) => {

    const getTdValue = (value, className) => {
        if (value?.includes("*")) {
            return <div className="d-flex justify-content-center">
                <Loading style={{width: "1.5vw"}} sizeDigit={value?.length}/>
            </div>
        }
        if (value?.includes("?")) {
            return <div className="d-flex justify-content-center">
                <Random
                    sizeDigit={value?.length}
                    className={`${isLive ? "draw-style-mt-live" : `draw-style-mt ${isQr ? "font-w-500" : ""}`}`}
                />
            </div>
        }
        return <div className={className}>{value}</div>
    }

    const getItemNight = () => {
        let notA = items.results.filter(e => !e.postCode.includes("A"));
        notA = notA.map(p => {
            return {
                postCode: p.postCode,
                items: [p]
            }
        })
        let aItems = items.results.filter(e => e.postCode.includes("A"));
        let postA = {postCode: "A", items: aItems}
        return [postA, ...notA]
    }


    return (
        <>
            <div className="mid_content text-center">
                <div className="table_result">
                    <div className="result_header">
                        <div className={`result_date date_time ${isQr ? "fs-result-vn p-result-head-vn" : ""}`}>
                            <span>{getDateDisplay(items.drawAt)} - {t("labels:shift")} {getTimeN(items.drawAt)} {t("labels:minutes")}</span>
                        </div>
                    </div>
                    <table width="100%" className="table" id={id ?? ""}>
                        <tbody className="">
                        {
                            getItemNight().map((e, i) => {
                                return <tr key={i}>
                                    <td>
                                        <table className="table">
                                            <tbody>
                                            <tr>
                                                <td rowSpan={2}
                                                    className={`post-vn border-td bg-post-night-${e.postCode} ${e.postCode === "I" ? "f-tnr" : ""} ${isQr ? "fs-qr-b-num-vn" : "fs-qr-num-vn"}`}
                                                    style={{width: `${isQr ? "15vw" : "8vw"}`}}>
                                                    {e.postCode}
                                                </td>
                                                {
                                                    e.items.map((p, j) => {
                                                        return <td
                                                            className={`td-number-vn border-td border-top-0 num-vn  text-post-night-${e.postCode} ${isQr ? "fs-qr-num" : "fs-qr-num-vn"}`}
                                                            key={j}>
                                                          <div className="d-flex justify-content-center">
                                                              <div
                                                                  className={`d-flex align-items-center justify-content-center  ${(isQr || !isLive) ? "" : "result-box-2d"}`}>
                                                                  {getTdValue(p.fiveD, (isQr || !isLive) ? "" : "num-space")}
                                                              </div>
                                                          </div>
                                                        </td>
                                                    })
                                                }
                                            </tr>

                                            </tbody>
                                        </table>
                                    </td>
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default NightFormMC;
