import React from "react";
import JackpotL from "../../assets/images/banner/live-left-6D.jpg";

const LeftSide = () => {
    return <div className="col-sm-2 p-0">
        <div className="card mb-0 shadow-sm" id="left-banner">
            <div className="card-body p-0">
                <img src={JackpotL} alt=""/>
            </div>
        </div>
    </div>
};

export default LeftSide;
