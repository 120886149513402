import MainTable from "../../../components/mainpage/mainTable";
import {connect} from "react-redux";
import {fetchResultByType, resultSocket} from "../../../redux/actions/resultActions";
import {withTranslation} from "react-i18next";
import React from "react";
import BodyMt from "../../../components/results/mt/bodyMt";

const ResultVn1Page = ({...props}) => {

    return (
        <MainTable>
            <BodyMt type="vn1" {...props}/>
        </MainTable>
    )
};

const mapStateToProps = state => ({
    historyResult: state.resultVn1.result,
    drawResult: state.resultVn1.draw
});


export default connect(
    mapStateToProps,
    {
        fetchResultByType,
        resultSocket
    }
)(withTranslation()(ResultVn1Page));
