import React from 'react';
import {useEffect, useState} from "react";


const RandomNumber = props => {
    const {className} = props;
    const [random, setRandom] = useState(0);

    useEffect(() => {
        const interval = setInterval(
            () => setRandom(Math.floor(Math.random() * 10)),
            65
        );
        return () => {
            clearInterval(interval);
        };
    }, []);


    return <span className={className}>{random}</span>

};


const Random = props => {
    const {sizeDigit} = props;
    const randoms = [];
    for (let i=0; i < sizeDigit; i++) {
        randoms.push(<RandomNumber key={i} {...props}/>);
    }
    return randoms;
};

export default Random;
