import {faHome} from "@fortawesome/free-solid-svg-icons";
import {faDiceD6, faNewspaper, faInfo,faPhone} from "@fortawesome/free-solid-svg-icons";
import {faAccusoft} from "@fortawesome/free-brands-svg-icons";
const menus = [
    {
        title: "menus:navigation.home",
        icon: faHome,
        url: "/",
    },
    {
        title: "menus:navigation.products",
        icon: faDiceD6,
        url: "/products/*",
        children: [
            {
                title: "menus:navigation.lmhsb-5d",
                url: '/products/lmhsb-5d',
            },
            {
                title: "menus:navigation.lmhsb-6d",
                url: '/products/lmhsb-6d',
            },
            {
                title: "menus:navigation.mt",
                url: '/products/mt',
            },
            {
                title: "menus:navigation.tn",
                url: '/products/tc',
            },
            {
                title: "menus:navigation.vn1",
                url: '/products/mc',
            },

            {
                title: "menus:navigation.sc",
                url: '/products/sc',
            },

        ]
    },
    {
        title: "menus:navigation.results",
        icon: faAccusoft,
        url: "/result/*",
        children: [
            {
                title: "menus:navigation.lmhsb-5d",
                url: '/result/lmhsb-5d',
            },
            {
                title: "menus:navigation.lmhsb-6d",
                url: '/result/lmhsb-6d',
            },
            {
                title: "menus:navigation.mt",
                url: '/result/mt',
            },
            {
                title: "menus:navigation.tn",
                url: '/result/tc',
            },
            {
                title: "menus:navigation.vn1",
                url: '/result/mc',
            },
            {
                title: "menus:navigation.sc",
                url: '/result/sc',
            },


        ]
    },

    {
        title: "menus:navigation.tips",
        icon: faInfo,
        url: "/tips/*",
        children: [
            {
                title: "menus:navigation.lmhsb-5d",
                url: '/tips/lmhsb-5d',
            },
            {
                title: "menus:navigation.lmhsb-6d",
                url: '/tips/lmhsb-6d',
            },
            {
                title: "menus:navigation.mt",
                url: '/tips/mt',
            },
            {
                title: "menus:navigation.tn",
                url: '/tips/tc',
            },
            {
                title: "menus:navigation.vn1",
                url: '/tips/mc',
            },

            {
                title: "menus:navigation.sc",
                url: '/tips/sc',
            },


        ]
    },

    {
        title: "menus:navigation.media",
        icon: faNewspaper,
        url: "/media",
    },

    {
        title: "menus:navigation.contact_us",
        url: "/contact-us",
        icon:faPhone
    },
]

export {menus}
