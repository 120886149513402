import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import ProductImage from "../../components/products/productImage";

const ProductPage = (props) => {
    let type = props.match.params["0"];

    return (
        <MainTable>
            <ProductImage src={require(`../../assets/images/products/${type}.jpg`)}/>
        </MainTable>
    )
};

export default ProductPage
