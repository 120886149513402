import io from 'socket.io-client';

const socket = io(process.env.REACT_APP_WEB_SOCKET, {
    reconnection: true,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    transports: ['websocket']
});

const bottomLinks = [
    {label: "LEAP5D", result: "/result/lmhsb-5d", tip: "/tips/lmhsb-5d"},
    {label: "LEAP6D", result: "/result/lmhsb-6d", tip: "/tips/lmhsb-6d"},
    {label: "MT", result: "/result/mt", tip: "/tips/mt"},
    {label: "TC", result: "/result/tc", tip: "/tips/tc"},
    {label: "MC", result: "/result/mc", tip: "/tips/mc"},
    {label: "SC", result: "/result/sc", tip: "/tips/sc"}
]

const titles = [
    "Albina Boyle",

    "Mattie Walter DVM",

    "Dr. Ewald DuBuque",

    "Ms. Aliya Towne PhD",

    "Malachi Barton"];
export {socket, bottomLinks,titles};

export const SCPostCode = {
    A: "ក",
    B: "ខ",
    C: "គ",
    D: "ឃ",
    E: "ង"
}
