import React, {useEffect} from "react";
import LeftSide from "../../components/live-leap/leftSide";
import RightSide from "../../components/live-leap/rightSide";
import BodyLive from "../../components/live-leap/body";
import ResultLive from "../../components/live-leap/result/resultLive";
import {connect} from "react-redux";
import {resultSocket} from "../../redux/actions/resultActions";
import _ from "lodash";
import {withTranslation} from "react-i18next";

const LivePage = ({...props}) => {
    const {draw6, draw5, resultSocket, t} = props;
    let data = _.isEmpty(draw5.data) ? draw6.data : draw5.data;
    useEffect(() => {
        resultSocket();
    }, [resultSocket]);

    return <div className="container-fluid position-relative live">
        <div className="row pt-0 pb-3">
            <LeftSide/>
            {
                _.isEmpty(data) ? <BodyLive t={t}/> : <ResultLive result={data} {...props}/>
            }
            <RightSide/>
        </div>
    </div>
};

const mapStateToProps = state => ({
    draw6: state.result.draw6,
    draw5: state.result.draw5
});

export default connect(mapStateToProps, {resultSocket})(withTranslation()(LivePage));
