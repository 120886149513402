import DrawTime from "../drawTime";
import ResultShowSc from "./resultShowSc";
import React, {useEffect, useState} from "react";
import ResultActiveSc from "./resultActiveSc";

const SCResultBody = (props) => {
    const {result: {list}, drawResult: {data}, type, t} = props;
    const [drawTime, setDrawTime] = useState(null);
    useEffect(() => {
        if (list?.length > 0) {
            const getAwarded = list?.filter(e => e.status === "AWARDED");
            const lastAwarded = getAwarded?.length - 1;
            setDrawTime(list.filter(e => e.status === "AWARDED")[lastAwarded]?.drawTime)
        }
    }, [list]);

    const onClickTime = (time) => {
        setDrawTime(time)
    };

    return <div className="mb-result bg-sc-result">
        <div className="sc-img">
            <img className="img-fluid" src={require(`../../../../assets/images/Result_SabayChneas.png`)} alt="3-min"/>
        </div>
        <div className="d-flex justify-content-center align-items-center p-2">
            <div className={` ${!(data.status === "ACTIVE") ? "p-2 pb-2" : ""}`}>
                {!(data.status === "ACTIVE") &&
                <DrawTime list={list} drawTime={drawTime} onClickTime={(time) => onClickTime(time)}/>}
                {(data.status === "ACTIVE") && <div className="">
                    <span className="text-danger f-2rem">{t("labels:live")}</span>
                </div>}
            </div>
            <div className="d-flex justify-content-center align-items-center social-icons-sc">
                <a rel="noreferrer" href="https://www.facebook.com/profile.php?id=100089999495230" target="_blank" className=""><img
                    src={require(`../../../../assets/images/sc/social-icons/fb.png`)} alt="facebook icons"/></a>
                <a rel="noreferrer" href="https://www.youtube.com/channel/UCIVmuKMlyoePiftNgqDb6Yg" target="_blank"><img
                    src={require(`../../../../assets/images/sc/social-icons/yt.png`)} alt="youtube icons"/></a>
                <a rel="noreferrer" href="https://t.me/sabaychhneas" target="_blank"><img
                    src={require(`../../../../assets/images/sc/social-icons/tg.png`)} alt="telegram icons"/></a>
                <a rel="noreferrer" href="https://www.tiktok.com/@sabbaychhneah" target="_blank"><img
                    src={require(`../../../../assets/images/sc/social-icons/tk.png`)} alt="tiktok icons"/></a>

            </div>
        </div>
        {
            data.status === "ACTIVE" && <ResultShowSc type={type} drawTime={drawTime} result={data}/>
        }
        {
            list?.map((result, i) => {
                const isShow = result.drawTime === drawTime;
                return (
                    (!(data.status === "ACTIVE") && isShow) && <div key={i}>
                        {
                            result.status === "AWARDED" ?
                                <ResultShowSc key={i}
                                              type={type}
                                              drawTime={drawTime}
                                              {...props}
                                              result={result}

                                />

                                : <ResultActiveSc key={i}
                                                  type={type}
                                                  drawTime={drawTime}
                                                  {...props}
                                                  result={result}

                                />
                        }
                    </div>
                )
            })
        }
    </div>
}


export default SCResultBody;
