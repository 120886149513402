import React from "react";
import loading from "../../assets/images/loading.gif";

const Loading = (props) => {

    const {sizeDigit, className, style} = props;
    const loads = [];
    for (let i = 0; i < sizeDigit; i++) {
        loads.push(<img alt="" style={style} className={className} key={i} src={loading}/>);
    }
    return loads;
};

export default Loading;
