import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import TipImage from "../../components/tips/tipImage";

const TipPage = (props) => {
    let type = props.match.params["0"];

    return (
        <MainTable>
            <TipImage src={require(`../../assets/images/tips/${type}.jpg`)}/>
        </MainTable>
    )
};

export default TipPage
