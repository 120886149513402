import React from "react"
import TopHead from "./topHead";
import Slider from "./slider";
import Menu from "./menu";
import Announcement from "./announcement";
import {withTranslation} from "react-i18next";

const Header = ({isMenu = true, isAnnouncement = true, ...props}) => {
    return <>
        <TopHead/>
        <div className="row head-menu">
            <div className="col-12">
                <div className="row bg-white">
                    {/*<SlideShow />*/}
                    <Slider {...props}/>
                </div>
                {isMenu && <div>
                    <Menu {...props}/>
                </div>}
            </div>
        </div>
        {isAnnouncement && <div className="row bg-c-blue mt-1 rounded">
            <Announcement/>
        </div>}
    </>
};

export default withTranslation()(Header);
