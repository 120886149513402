import React, {useEffect} from "react";
import MidSide from "./midSide";


const Body = ({...props}) => {
    const {fetchResults, location: {search}, resultSocket, type, fetchDrawStatus} = props;
    const date = search.replaceAll("?search=", "");
    const filterByDate = date === "" ? null : date;


    useEffect(() => {
        let params = {
            filterByDate: filterByDate,
            filterByMode: "popular"
        }
        let isFetch = false;
        const getResult = () => {
            if (!isFetch) {
                fetchResults(params);
                fetchDrawStatus();
                isFetch = true
            }
        };
        fetchResults(params);
        fetchDrawStatus();
        resultSocket(getResult);
    }, [fetchResults, filterByDate, resultSocket, fetchDrawStatus]);


    return <div className="row pt-2 pb-3">
        <MidSide {...props} type={type}/>
    </div>
};

export default Body;
