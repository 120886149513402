import React, {useEffect} from 'react';
import MidSideMt from "./midSideMt";
import LeftSide from "../side/leftSide";
import RightSide from "../../mainpage/body/rightSide";

const BodyMt = ({...props}) => {

    const {drawResult: {data}, historyResult, location: {search}, fetchResultByType, resultSocket, type} = props;

    const date = search.replaceAll("?search=", "");
    const filterByDate = date === "" ? null : date;

    useEffect(() => {
        let isFetch = false;
        const getResult = () => {
            if (!isFetch) {
                fetchResultByType({filterByDate: filterByDate}, type);
                isFetch = true
            }
        };
        fetchResultByType({filterByDate: filterByDate}, type);
        resultSocket(getResult);
    }, [fetchResultByType, filterByDate, resultSocket,type]);

    return <div id="mt-result" className="row pt-2 pb-3">
        <LeftSide/>
        <MidSideMt results={historyResult} draw={data} {...props}/>
        <RightSide {...props}/>
    </div>
};

export default BodyMt;
