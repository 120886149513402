import React from "react"
import SvgFacebook from "../../../assets/images/socialicon/svg_facebook.svg"
import SvgYoutube from "../../../assets/images/socialicon/svg_youtube.svg"
import SvgTikTok from "../../../assets/images/socialicon/svg_tiktok.svg"
import SvgTelegram from "../../../assets/images/socialicon/svg_telegram.svg"
import {getYear} from "../../../constant/dateUtils";
import {Link} from "react-router-dom";
import {withTranslation} from "react-i18next"

const Footer = ({t}) => {
    const version = process.env.REACT_APP_VERSION;

    return <footer className="footer mt-auto bg-light container-fluid">
        <div className="row bg-grey py-3">
            <div className="col-md-12 text-center">
                <div className="logo-part mt-2">
                    <h5 className="text-uppercase f-t-50">{t("menus:navigation.lmhsb_lotto")}</h5>
                    <p className="f-t-34">
                        {t("labels:sale_agency")} : បុរីអ័រគីដេ២ ផ្ទះលេខ.២៦, ២៨ ផ្លូវ.និរតី (២០០៤) សង្កាត់.អូរបែកក្អម
                        ខណ្ឌ សែនសុខ រាជធានី ភ្នំពេញ
                    </p>
                </div>
            </div>
            <hr className="w-100 mt-0 border-white"/>
            <div className="col-md-12 text-center">
                <ul className="f-t-40">
                    <li><Link to="/">{t("menus:navigation.home")}</Link></li>
                    <li><Link to="/lmhsb">{t("menus:navigation.products")}</Link></li>
                    <li><Link to="/result/lmhsb">{t("menus:navigation.results")}</Link></li>
                    <li><Link to="/media">{t("menus:navigation.media")}</Link></li>
                    <li><Link to="/lmhsb-tips">{t("menus:navigation.tips")}</Link></li>
                    <li><Link to="/contact-us">{t("menus:navigation.contact")}</Link></li>
                </ul>
            </div>

            <div className="col-md-12 text-center">
                <div className="social text-center">
                    <a href="https://www.facebook.com/lmhsb168168/" target="_blank" rel="noreferrer">
                        <img src={SvgFacebook}
                             className="social-icon"
                             alt="facebook"/></a>
                    <a href="https://t.me/resultleapmorhasombathlotto" target="_blank" rel="noreferrer"><img
                        src={SvgTelegram} className="social-icon"
                        alt="telegram"/></a>
                    <a href="https://www.youtube.com/@leapmohasombath" target="_blank" rel="noreferrer"><img
                        src={SvgYoutube} className="social-icon border rounded-circle"
                        alt="youtube"/></a>
                    <a href="https://www.tiktok.com/@leapmorhasombath?_t=8cRZs8IaFaE" target="_blank" rel="noreferrer"><img
                        src={SvgTikTok} className="social-icon"
                        alt="tiktok"/></a>
                </div>
            </div>

        </div>
        <div className="row bg-dark">
            <div className="text-center text-white p-1 w-100">
                <span
                    className="footer-bottom-text f-t-40">© {getYear()} រក្សាសិទ្ធិ: Leapmorhasombath Lotto Co., Ltd</span>
                {version && <span className="f-version ml-2 text-version">V{version}</span>}
            </div>
        </div>
    </footer>
};

export default withTranslation()(Footer);
