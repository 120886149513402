import React from "react";
import LeapResultBody from "./leapResultBody";
import ProductFilterBottom from "./productFilterBottom";
import RenderMt from "./mtResultBody";
import SCResultBody from "./sc/scResultBody";

const MidSide = (props) => {
    const {results: {result5d, result6d, draw6, draw5}, t, resultMt, type, resultTn,
        resultVn1, resultSc, drawSc
    } = props;

    return <div className="col-sm-7 shift-result" style={{height: "100%"}}>

        {(type === "sc" || !type)&& <SCResultBody
            type="sc"
            drawResult={drawSc}
            result={resultSc}
            t={t}/>}

        {(type === 5 || !type) && <LeapResultBody
            type={5}
            drawResult={draw5}
            result={result5d}
            t={t}/>}
        {(type === 6 || !type) && <LeapResultBody
            type={6}
            drawResult={draw6}
            result={result6d}
            t={t}/>}
        {!type && <RenderMt
            results={resultMt.result}
            draw={resultMt.draw.data}
            type="mt"/>}
        {!type && <RenderMt
            results={resultTn.result}
            draw={resultTn.draw.data}
            type="tn"/>}
        {!type && <RenderMt
            results={resultVn1.result}
            draw={resultVn1.draw.data}
            type="vn1"/>}

        <ProductFilterBottom t={t}/>
    </div>
};

export default (MidSide);
