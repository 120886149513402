import React, {useEffect} from "react";
import {connect} from "react-redux";
import {fetchResultByType, resultSocket} from "../../redux/actions/resultActions";
import {withTranslation} from "react-i18next";
import _ from "lodash";
import LeftSide from "../../components/live-leap/leftSide";
import RightSide from "../../components/live-leap/rightSide";
import MidSideMt from "../../components/results/mt/midSideMt";
import BodyLive from "../../components/live-leap/body";

const LiveMTPage = (props) => {
    const {drawResult: {data}, historyResult, fetchResultByType, resultSocket, t} = props;

    useEffect(() => {
        let isFetch = false;
        const getResult = () => {
            if (!isFetch) {
                fetchResultByType("mt");
                isFetch = true
            }
        };
        fetchResultByType("mt");
        resultSocket(getResult);
    }, [fetchResultByType, resultSocket]);

    return <div id="mt-result" className="position-relative live">
        <div className="d-flex pt-0 pb-3">
            <LeftSide/>

            {
                _.isEmpty(data) ? <BodyLive t={t} type="mt"/> :
                    <MidSideMt isLive liveClass="live-padding" isTabView type="mt" results={historyResult} draw={data} {...props}/>
            }
            <RightSide/>
        </div>
    </div>
};

const mapStateToProps = state => ({
    historyResult: state.resultMt.result,
    drawResult: state.resultMt.draw
});

export default connect(
    mapStateToProps,
    {
        fetchResultByType,
        resultSocket
    }
)(withTranslation()(LiveMTPage));
