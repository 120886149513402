import { DRAW_RESULT_SC } from "./types";

const init = {
    isLoading: false,
    data: {},
};

const draw = (state = init, {type, payload}) => {
    if (type === DRAW_RESULT_SC) {
        return {
            ...state,
            isLoading: false,
            data: payload
        };
    } else {
        return state;
    }
};

export default draw;
