import { LIST_DRAW_STATUS } from "./types";

const init = {
    data: {}
};
const drawStatus =  (state = init, { type, payload }) => {
    switch (type) {
        case LIST_DRAW_STATUS:
            return {
                ...state,
                isLoading: false,
                data: payload
            };
        default:
            return state;
    }

}
export default drawStatus;
