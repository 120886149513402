import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPrint} from "@fortawesome/free-solid-svg-icons";
import ReactToPrint from "react-to-print";
import PrintSixD58 from "./printSixD58";

const SixDPrint = ({...props}) => {
    const {results: {list}} = props;

    const renderPrintsX8 = (object) => {
        let prints = [];
        for (let i = 0; i < 8; i++) {
            prints.push(
                <PrintSixD58 key={i} className="ml-3 print58" printDigit={1} object={object}/>
            )
        }
        return prints;
    };

    return <div className="row col-sm-12">
        {
            list.map((result, i) => {
                let componentRef = React.createRef();
                let componentRefX4 = React.createRef();
                return <div key={i} className="col-sm-3">
                    <div className="d-flex justify-content-between">
                        {/*Print One*/}
                        <ReactToPrint
                            trigger={() => <button className="bg-transparent border-0">
                                <FontAwesomeIcon style={{fontSize: 32}} className="text-red-c mt-1"
                                                 icon={faPrint}/>
                            </button>}
                            content={() => componentRef}
                        />
                        <div style={{display: "none"}}>
                            <div ref={el => (componentRef = el)}>
                                <PrintSixD58 printDigit={1} object={result}/>
                            </div>
                        </div>

                        {/*Print X4*/}
                        <ReactToPrint
                            trigger={() => <button style={{fontSize: 32, marginRight: "4.5vw"}}
                                                   className="bg-transparent border-0 text-red-c mt-1 f-time-roman">
                                <FontAwesomeIcon icon={faPrint}/>x8
                            </button>}
                            content={() => componentRefX4}
                        />
                    </div>

                    <div style={{display: "none"}}>
                        <div style={{width:"297mm"}} className="row" ref={el => (componentRefX4 = el)}>
                            {
                                renderPrintsX8(result)
                            }
                        </div>
                    </div>

                    <div className="row">
                        <PrintSixD58 isShow printDigit={1} key={i} className="ml-3 print80" object={result}/>
                    </div>
                </div>
            })
        }
    </div>
};

export default SixDPrint;