import SixD from "../../../assets/images/banner/6D.gif";
import LungChet from "../../../assets/images/banner/lung-chet.png";
import ResultLeapB from "../../../assets/images/banner/result-leapB.png";
import {Link} from "react-router-dom";
import React from "react";
import {bottomLinks} from "../../../constant/General";

const ProductFilterBottom =({t})=><>
    <div id="leap-result" className="print6D">
        <div className="tab-content">
            <div className="tab-pane fade"
                 id="leap{{$leap->id}}" role="tabpanel">
                <div className="card-product lottery-card-header">
                    <img className="img-fluid w-100" src={SixD} alt=""/>
                </div>

                <div className="card-product lottery-card-header">
                    <img className="img-fluid" src={LungChet} alt=""/>
                </div>
            </div>
        </div>
        <div style={{display: "none"}}>
            <div className="row A4" id="printA46d">
            </div>
        </div>
        <div style={{display: "none"}}>
            <div className="row A4" id="printA4Leap">
            </div>
        </div>
    </div>
    <hr className="border-dark"/>
    <div className="card-product lottery-card-header">
        <img className="img-fluid" src={ResultLeapB} alt=""/>
    </div>
    <div id="product-filter">
        <div className="w-100 mt-1">
            <table style={{width: "100%"}} className="table table-bordered" id="table-result-mid">
                <tbody>
                {bottomLinks.map((e,i)=>{
                    return  <tr key={i} className="head-post head-post-filter ">
                        <td className="result-tb table-mid" style={{width: "25%"}}>{t("labels:".concat(e.label))}</td>
                        <td className="result-tb table-mid under-line" style={{width: "37%", color: "#1011dc"}}><Link
                            to={e.result}>{t("labels:result")}</Link>
                        </td>
                        <td className="result-tb table-mid under-line" style={{width: "37%"}}><Link
                            to={e.tip}>{t("labels:tips")}</Link>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    </div></>
export default ProductFilterBottom;
