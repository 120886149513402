import React from "react";
import {getDatePrint} from "../../../../constant/dateUtils";
import {posts, sorts} from "../../../../utility/General";

const Print58 = ({...props}) => {
    const {object: {results, drawAt}, className, isShow} = props;
    const isValidNum = (num) => (num.includes("*") || num.includes("?")) ? "" : num;

    return (
        <div className={isShow ? "mb-4" : ""}>
            <div id="print-leap" className={className ?? "print58"}>
                <table>
                    <tbody>
                    <tr>
                        <td colSpan="4" className="f-print-head">លទ្ធផលឆ្នោតមហាលាភសម្បត្តិ</td>
                    </tr>
                    <tr>
                        <td colSpan="4">{getDatePrint(drawAt)}</td>
                    </tr>

                    <tr>
                        <td style={{width: "20%"}} className="post">A</td>
                        <td style={{width: "30%"}}>{isValidNum(results[0][0].slice(-4))}</td>
                        <td style={{width: "30%"}}>{isValidNum(results[0][0].slice(-3))}</td>
                        <td style={{width: "30%"}}>{isValidNum(results[0][0].slice(-2))}</td>
                    </tr>


                    {posts.map((item, p) => {
                        const resultPosts = results[2][sorts[p]];

                        const renderPostNum = () => {
                            return (
                                <tr key={p}>
                                    <td className="post">
                                        {item}
                                    </td>
                                    <td>{isValidNum(resultPosts.slice(-4))}</td>
                                    <td>{isValidNum(resultPosts.slice(-3))}</td>
                                    <td>{isValidNum(resultPosts.slice(-2))}</td>
                                </tr>
                            );
                        };
                        return renderPostNum();
                    })}

                    {results[1].map((item, i) => {
                        return (
                            <tr key={i}>
                                <td>{i + 1}</td>
                                {i === 0 && (
                                    <td rowSpan="20" style={{verticalAlign: "middle"}}>
                                        <span className="post-lo">រង្វាន់លួងចិត្ត</span>
                                    </td>
                                )}
                                <td colSpan={2}>{isValidNum(item)}</td>
                            </tr>
                        );
                    })}
                    <tr>
                        {!isShow && <td style={{border: 0}}>-</td>}
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    )
};

export default Print58;
