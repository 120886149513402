import TipPage from "./views/tips";
import MainAboutUsPage from "./views/aboutus";
import MainMediaPage from "./views/media";
import ResultMTPage from "./views/results/mt";
import LivePage from "./views/live-leap";
import PrintLeapResults from "./views/results/print/index";
import Print6DResults from "./views/results/print/index6d";
import ResultTNPage from "./views/results/tn";
import ResultVn1Page from "./views/results/vn1";
import FiveDPage from "./views/leap/5d";
import Leap6DPage from "./views/leap/6d";
import MainPage from "./views/leap";
import QrCodeResultPage from "./views/qr-results";
import ProductPage from "./views/products";
import LiveMTPage from "./views/live-mt";
import LiveTNPage from "./views/live-tn";
import LiveMCPage from "./views/live-mc";
import SCPage from "./views/sc/sc";
import LiveSCPage from "./views/live-sc";

const RouteList = [
    {
        exact: true,
        path: "/",
        name: "Home",
        component: (MainPage)
    },
    // Products
    {
        exact: true,
        path: "/products/*",
        name: "products",
        component: (ProductPage)
    },

    // Result

    {
        exact: true,
        path: "/result/lmhsb-6d",
        name: "Result LMHSB 6D",
        component: (Leap6DPage)
    },

    {
        exact: true,
        path: "/result/lmhsb-5d",
        name: "Result 6D",
        component: (FiveDPage)
    },

    {
        exact: true,
        path: "/result/mt",
        name: "Result MT",
        component: (ResultMTPage)
    },

    {
        exact: true,
        path: "/result/tc",
        name: "Result TC",
        component: (ResultTNPage)
    },

    {
        exact: true,
        path: "/result/mc",
        name: "Result MC",
        component: (ResultVn1Page)
    },

    {
        exact: true,
        path: "/result/sc",
        name: "Result SC",
        component: (SCPage)
    },

    {
        exact: true,
        path: "/print/lmhsb/:date",
        name: "Print Leap Result",
        component: (PrintLeapResults)
    },

    {
        exact: true,
        path: "/print/sixd/:date",
        name: "Print 6D Result",
        component: (Print6DResults)
    },

    // How to use (Tips) //
    {
        exact: true,
        path: "/lmhsb-tips",
        name: "Lmhsb",
        component: (TipPage)
    },
    {
        exact: true,
        path: "/tips/*",
        name: "Six D",
        component: (TipPage)
    },

    // About Us -> Contact US
    {
        exact: true,
        path: "/contact-us",
        name: "About Us",
        component: (MainAboutUsPage)
    },

    //Media
    {
        exact: true,
        path: "/media",
        name: "Media",
        component: (MainMediaPage)
    },
    //Detail Media
    {
        exact: true,
        path: "/detail",
        name: "Media",
        component: (MainMediaPage)
    },

    //Live Page Leap
    {
        exact: true,
        path: "/live-leap",
        name: "Live",
        component: (LivePage)
    },

    //Live Page MT
    {
        exact: true,
        path: "/live-mt",
        name: "Live-MT",
        component: (LiveMTPage)
    },
    //Live Page TN
    {
        exact: true,
        path: "/live-tc",
        name: "Live-Tn",
        component: (LiveTNPage)
    },
    //Live Page MC
    {
        exact: true,
        path: "/live-mc",
        name: "Live-MC",
        component: (LiveMCPage)
    },

    {
        exact: true,
        path: "/live-sc",
        name: "Live-SC",
        component: (LiveSCPage)
    },

    // Qrcode
    {
        exact: true,
        path: "/qrcode-results",
        name: "Qr Code Result Page",
        component: (QrCodeResultPage)
    },
];

export default RouteList
