import MidSideMt from "../../results/mt/midSideMt";
import React from "react";
import {withTranslation} from "react-i18next";

const RenderMt = ({results, draw, type, t}) => {
    return <div id="mt-result" className="mb-result">
        <MidSideMt
            results={results}
            draw={draw}
            type={type}
            t={t}
            isTabView/>
    </div>
}

export default withTranslation()(RenderMt);
