import React from 'react';
import PrintSixD58 from "./printSixD58";
import ReactToPrint from "react-to-print";

let componentRef = React.createRef();
const PrintA4 = ({...props}) => {
    const {list, t} = props;

    return (
        <div>
            <ReactToPrint
                trigger={() => <button id="printa4"
                                       className="btn btn-outline-success">{t("labels:print_all")} (A4)</button>}
                content={() => componentRef}
            />
            <div style={{display: "none"}}>
                <div style={{width:"297mm"}} className="row" ref={el => (componentRef = el)}>
                    {
                        list.map((result, i) => {
                            return <PrintSixD58 printDigit={1} key={i} className="ml-3 print58" object={result}/>
                        })
                    }
                </div>
            </div>
        </div>

    )
};

export default PrintA4;
