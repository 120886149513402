import React from "react"

const DrawTime = ({isQr = false, ...props}) => {
    const {onClickTime, list} = props;
    const getAwarded = list?.filter(e => e.status === "AWARDED");
    const lastAwarded = getAwarded?.length - 1;
    const activeItem = getAwarded?.length > 0 ? getAwarded[lastAwarded] : [];

    return <ul className="nav nav-tabs affix justify-content-center w-100" role="tablist" id="scrollFix">
        {
            list?.map((item, i) => {
                return <li key={i} onClick={() => onClickTime(item.drawTime)} className="nav-item nav-header-shift mr-2"
                           role="presentation">
                    <a role="tab" data-toggle="tab" href="/"
                       className={`nav-link py-1 px-2 m-0 ${activeItem?.drawCode === item.drawCode ? "active" : ""}`}>
                        <span
                            className={`result-nav-hour ${isQr ? "fs-result-draw p-result-draw-vn" : ""}`}>{item.drawTime}</span>
                    </a>
                </li>
            })
        }
    </ul>
};

export default DrawTime;
