import React from "react";
import bannerTop01 from "../../../assets/images/slideshow/b-top1.jpg";
import bannerTop02 from "../../../assets/images/slideshow/b-top2.jpg";
import bannerTop03 from "../../../assets/images/slideshow/b-top3.jpg";
import {Link} from "react-router-dom";

const Slider = ({isActionSlider = true, ...props}) => {
    return <div className="col-12 p-0">
        <div id="carouselIndicators" className="carousel slide" data-ride="carousel">
            <ol className="carousel-indicators">
                <li data-target="#carouselIndicators" data-slide-to="0" className="active"/>
                <li data-target="#carouselIndicators" data-slide-to="1"/>
                <li data-target="#carouselIndicators" data-slide-to="2"/>
            </ol>
            <div className="carousel-inner">
                <div className="carousel-item active">
                    {isActionSlider ? <Link to="/">
                        <img className="d-block w-100" src={bannerTop01} alt="First slide"/>
                    </Link> : <img className="d-block w-100" src={bannerTop01} alt="First slide"/>}
                </div>
                <div className="carousel-item">
                    {isActionSlider ? <Link to="/">
                        <img className="d-block w-100" src={bannerTop02} alt="Second slide"/>
                    </Link> : <img className="d-block w-100" src={bannerTop02} alt="Second slide"/>}
                </div>
                <div className="carousel-item">
                    {isActionSlider ? <Link to="/">
                        <img className="d-block w-100" src={bannerTop03} alt="Third slide"/>
                    </Link> : <img className="d-block w-100" src={bannerTop03} alt="Third slide"/>}
                </div>
            </div>
            <a className="carousel-control-prev" href="#carouselIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"/>
            </a>
            <a className="carousel-control-next" href="#carouselIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"/>
            </a>
        </div>
    </div>

};

export default Slider;
