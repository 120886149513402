import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import MainAboutUs from "../../components/aboutus/mainAboutUs";

const MainAboutUsPage = () => {
    return (
        <MainTable>
            <MainAboutUs/>
        </MainTable>
    )
};

export default MainAboutUsPage
