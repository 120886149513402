import React, {useState} from "react";
import Loading from "../../results/loading";
import Random from "../../results/random";
import {digits, onChangeDigits, onMouseEnter, onMouseLeave} from "../../../utility/General";
import {withTranslation} from "react-i18next";
import {getDateDisplay} from "../../../utility/dateUtils";


const ResultShow = (props) => {
    const {i18n, result, type, t} = props;
    const [activeDigit, setActiveDigit] = useState(0);
    const isKm = i18n.language === "km";

    let postA = result?.results[0][0].slice(-(type));


    const renderPostA = () => {
        if (postA.includes("*")) {
            return <span className="align-middle"><Loading sizeDigit={postA.length} className="loading-draw"/></span>
        } else if (postA.includes("?")) {
            return <span className="align-middle text-post-A"><Random sizeDigit={postA.length}
                                                          className="draw-style-a"/></span>
        } else return <span
            className={`big-result f-result-num-ba fw-none align-middle text-center text-post-A pop-able-number-${type}`}
            id="jack-port"
            data-num={postA}>{postA}</span>
    };

    return <div id="result-show" className="animate-slide-up text-white">
        <div className="card-product prod-width p-0">
            <div className="row col-sm-12 m-0 p-0">
                <div className="col-sm-12 bg-c-blue text-center text-yellow py-2" align="left">
                    <span
                        className="fw-bold head-result-date">{getDateDisplay(result.drawDate)} {t("labels:shift")} : {result.drawTime}</span>
                </div>
                {/*{!(result.status === "ACTIVE") && <div className="col-sm-4" align="right">*/}
                {/*    <PrintButton {...props} className="text-red-c mt-3">*/}
                {/*        {*/}
                {/*            <Print58 object={result}/>*/}
                {/*        }*/}
                {/*    </PrintButton>*/}
                {/*</div>}*/}
            </div>
            <hr className="m-0 border-dark"/>
            <div className="row m-0 post-a" id="post-a">
                <div className="col-2 py-5 card-border-right text-center post-a-vertical-middle bg-post-A">
                    <p className={`align-middle ${isKm ? "f-result" : "f-result-en"}`}>{t("labels:grand_price")}</p>
                </div>
                <div className="col-10 text-center p-post-a big-result-loading align-self-center">
                <span
                    className="result-highlight big-result align-middle text-red-c f-tnr">A</span>
                    <span className="m-1 result-highlight big-result align-middle ml-2 mr-2 text-post-A">=</span>
                    {
                        renderPostA()
                    }
                </div>
            </div>
            <hr className="m-0 border-dark"/>
            <div className="row m-0 p-0">
                <div className="col-2 card-border-right text-center lo-vertical-middle bg-blue-dark">
                    <p className={isKm ? "f-result" : "f-result-en"}>{t("labels:reward_consolation")}<br/>{t("labels:consolation")}
                    </p>
                </div>
                <div className="col-10 mt-3 row mb-3">
                    {
                        result?.results[1].map((item, i) => {
                            if (item.includes("*")) {
                                return <div key={i} className="col-sm-3 text-center p-0 mb-4">
                                        <span className="result-highlight lo-result" key={i}><Loading
                                            sizeDigit={5}
                                            className="lo-loading mr-2"/></span>
                                </div>
                            }
                            if (item.includes("?")) {
                                return (<div key={i} className="col-sm-3 text-center p-0 mb-4">
                                    <span className="result-highlight lo-result" key={i}><Random
                                        sizeDigit={5}
                                        className="draw-style"/></span>
                                </div>)
                            }
                            return <div key={i} className="col-sm-3 text-center mb-4 p-0">
                                       <span className={`result-highlight lo-result pop-able-number-${type}`}
                                             data-length="5"
                                             data-num={item}>{item}</span>

                            </div>
                        })
                    }
                </div>
            </div>
            <hr className="m-0 border-dark"/>
            <div className="row m-0 post-special d-flex" id="post-spec">
                <div className="col-2 card-border-right text-center post-vertical-middle pr-0 bg-post-F">
                    <p className={`${isKm ? "f-result" : "f-result-en"}`}>{t("labels:reward_spacial")}<br/>{t("labels:spacial")}
                    </p>
                </div>
                <div className="col-10 mt-2 mb-0">
                    <div className="row mr-1">
                        {
                            ["B", "F", "C", "I", "D", "N"].map((item, i) => {
                                let number = result?.results[2][i].slice(-(type));
                                const renderPostNum = () => {
                                    if (number.includes("*")) {
                                        return <span className="align-middle" key={i}>
                                                    <Loading
                                                        sizeDigit={number.length}
                                                        className="loading-draw"/>
                                               </span>
                                    } else if (number.includes("?")) {
                                        return <span className="align-middle" key={i}>
                                                    <Random sizeDigit={number.length}
                                                            className="result-highlight spacial-result draw-spacial-result text-post-F"/>
                                               </span>
                                    } else return <span key={i}
                                                        className={`result-highlight align-middle spacial-result text-blue-c pop-able-number-${type} text-post-F`}
                                                        data-num={number}>{number}</span>


                                };
                                return (
                                    <div key={i}
                                         className={`col-6 p-0 text-center mb-2 pr-0 ${i < 4 ? "card-border-bottom" : ""}`}>
                                        <div
                                            className={`text-center mb-4 mt-3 spacial-result-loading ${i % 2 !== 1 ? "card-border-right" : ""}`}>
                                            <span
                                                className={`mt-2 result-highlight spacial-result  text-red-c mb-0 align-middle ${item === "I" ? "f-tnr" : ""}`}>{item}</span>
                                            <span
                                                className="m-1 result-highlight spacial-result text-blue-c align-middle text-post-F">=</span>
                                            {
                                                renderPostNum()
                                            }
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
        {/*Search Result*/}
        {!(result.status === "ACTIVE") && <div className="card-product boxBottom row p-0 m-0 text-dark">
            <div className="p-2 filter-balls">
                {
                    digits.map((e, i) => {
                        let active = e.value === activeDigit ? " active-digit" : "";
                        return <span key={i}
                                     onClick={_ => onChangeDigits(type, e.value, activeDigit, setActiveDigit)}
                                     className={`filter-digits fw-digit ${active}`}>{t(`labels:${e.label}`)}</span>
                    })
                }
            </div>
            {
                Array(10).fill(0).map((_, i) => {
                    return <div key={i}
                                className="ball-num"
                                onMouseEnter={() => onMouseEnter(type, i, activeDigit)}
                                onMouseLeave={() => onMouseLeave(type, activeDigit)}
                    >
                        <span className="p-0">{i}</span>
                    </div>
                })
            }

        </div>}
    </div>
};

export default (withTranslation()(ResultShow));
