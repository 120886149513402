import React from "react";
import {titles} from "../../constant/General";


const MainMedia = () => {

    return <div className="mt-2">
        {Array(5).fill(0).map((e, i) => {
            return <div key={i} className="row card-media">
                <div className="grid-news">
                    <div className="image">
                        <h1 className="sub_title text-primary p-2">{titles[i]}</h1>
                        <img alt="random" src={require(`../../assets/images/media/${i}.jpg`)}/>
                    </div>

                    <div className="text">
                        <p className="description">At quibusdam voluptatibus soluta voluptate sint soluta enim. Quis
                            quos
                            porro voluptas reprehenderit animi rem amet. Sit et occaecati velit voluptatem. Ea adipisci
                            et
                            est sed aut.</p>
                        <p className="post-meta"><span className="timestamp card-clock">
                            <i
                                className="fa fa-clock"/>25-2-2022</span></p>
                        <a href="/" className="mt-auto btn btn-primary  ">Read More</a>

                    </div>
                </div>

                <hr style={{width: "95%", margin: "auto"}}/>
                <hr/>
            </div>
        })}


    </div>
};

export default MainMedia;
