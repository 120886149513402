import React from "react";
import JackpotR from "../../assets/images/banner/live-left-6D.jpg";

const RightSide = () => {
    return <div className="col-sm-2 p-0 overflow-hidden">
        <div className="card mb-0 mt-0 shadow-sm" id="hot-promotion">
            <div className="card-body p-0">
                <img src={JackpotR} alt=""/>
            </div>
        </div>
    </div>
};

export default RightSide;
