import React, {useEffect, useState} from 'react';
import _ from "lodash";
import Result_MorhaTep from "../../../assets/images/Result_MorhaTep.jpg";
import Result_MorhaChneas from "../../../assets/images/Result_MorhaChneas.jpg";
import Result_TinhChneas from "../../../assets/images/Result_TinhChneas.jpg";
import DrawTime from "../../mainpage/body/drawTime";
import NightFormMC from "../mc/NightFormMC";
import DayFormMC from "../mc/DayFormMC";

const MidSideMt = (props) => {
    let {results: {list: {items}}, draw, type, isTabView, liveClass, isQr} = props;
    const [drawTime, setDrawTime] = useState(null);
    const isVn1 = type === "vn1";
    useEffect(() => {
        if (items?.length > 0) {
            const getAwarded = items?.filter(e => e.status === "AWARDED");
            const lastAwarded = getAwarded?.length - 1;
            let dt = items.filter(e => e.status === "AWARDED")[lastAwarded]?.drawTime ?? items[0].drawTime;
            setDrawTime(dt);
        }
    }, [items]);

    const onClickTime = (time) => {
        setDrawTime(time)
    };

    // const RenderMtForm=({items,i})=>{
    //     return  items?.isNight ? <NightFormMT
    //         items={items}
    //         {...props}
    //         indexResult={i}
    //     /> : <DayFormMT
    //         indexResult={i}
    //         items={items}
    //         {...props}
    //     />
    // }

    const RenderMcForm=({items,i})=>{
        return  items?.isNight ? <NightFormMC
            items={items}
            {...props}
            indexResult={i}
        /> : <DayFormMC
            indexResult={i}
            items={items}
            {...props}
        />
    }


    const RenderBodyForm=({items,i})=>{
        return <RenderMcForm i={i} items={items} />
    }

    const RenderMcFormDrawing=()=>{
     return draw?.isNight
            ? <NightFormMC isDraw items={draw} {...props} />
            : <DayFormMC items={draw} {...props} />
        // return draw?.isNight
        //     ? <NightFormMT isDraw items={draw} {...props} />
        //     : <DayFormMT items={draw} {...props} />
    }



    return <div className={`${isTabView ? "" : "col-sm-7"} shift-result ${liveClass}  ${type}`} style={{height: "100%"}}>

        <div className={`card-product lottery-card-header`}>
            {
                type === "mt" ? <img className="img-fluid" src={Result_MorhaTep} alt=""/> : type === "tn" ?
                    <img className="img-fluid" src={Result_TinhChneas} alt=""/> :
                    <img className="img-fluid" src={Result_MorhaChneas} alt=""/>
            }
        </div>


        {!_.isEmpty(draw) && <RenderMcFormDrawing/>}

        {!(isTabView && !_.isEmpty(draw)) && <>
            {isTabView && <div className={`col header-date-homepage ${isQr && !isVn1 ? "" : "pb-3 p-2"}`}>
                {!(draw.status === "ACTIVE") && (isVn1 || !isQr) &&
                <DrawTime list={items} drawTime={drawTime} onClickTime={(time) => onClickTime(time)} isQr={isQr}/>}
            </div>}
            {
                items?.map((items, i) => {
                    const isShow = items.drawTime === drawTime;
                    if (!(isTabView && !isShow)) {
                        return <div key={i}>
                           <RenderBodyForm items={items} i={i}/>
                        </div>
                    }
                    return <div key={i}/>

                })
            }

        </>}


    </div>
};

export default (MidSideMt);
