import React from "react"
import bannerLeft from "../../../assets/images/slideshow/b-left.jpeg"
import playStoreDownload from "../../../assets/images/playstordownload.png"
import left6D from "../../../assets/images/banner/left-6D.jpg"
import {withTranslation} from "react-i18next"

const LeftSide = ({t}) => {


    return <div className="col-sm-2 p-0">
        <div className="card mb-1 shadow-sm" id="left-banner">
            <div className="custom-card m-0">
                <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:lotto_history")}</h5>
            </div>
            <div className="card-body p-0">
                <a href="/#">
                    <img src={bannerLeft} alt=""/>
                </a>
            </div>
        </div>
        <div className="btnDownload card-product pt-2 mb-1">
            <p className="f-t-28">{t("labels:download_app")}</p>
            <a href="https://drive.google.com/file/d/1UF8bKDaEJRJoNWSHG97ReJQRb4TbfHi_/view?usp=sharing">
                <img src={playStoreDownload} alt="" className="responsive img-fluid"/>
            </a>
        </div>
        <div className="card mb-1 shadow-sm" id="left-banner">
            <div className="custom-card m-0">
                <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:hot_promotion")}</h5>
            </div>
            <div className="card-body p-0">
                <a href="/#">
                    <img src={left6D} alt=""/>
                </a>
            </div>
        </div>
    </div>
};

export default withTranslation()(LeftSide);
