import React from "react";
import Loading from "../results/loading";
import Random from "../results/random";
import BannerLive from "../../assets/images/Result_SabayChneas.png";
import {getDateDisplay} from "../../utility/dateUtils";
import {SCPostCode} from "../../constant/General";

const ResultLiveSC = ({...props}) => {

    const {t, result} = props;

    return <div className="col-sm-8 px-2 text-center card-product">
        <div className="mt-0 mb-0 align-center" id="post-a">
            <div className=" lottery-header-img">
                <img className="img-fluid" src={BannerLive} alt=""/>
            </div>
            <div className="col p-2 pt-2 ">
                <div className="row col-sm-12 m-0 p-0">
                    <div className="col-sm-12 bg-c-blue text-center text-yellow py-2" align="left">
                    <span
                        className="fw-bold head-result-date">{getDateDisplay(result.drawDate)} {t("labels:shift")} : {result.drawTime}</span>
                    </div>
                </div>
            </div>
        </div>
        <div className="text-center p-lr-vw">
            {
                result.results?.map((e, i)=>{
                    const postNumber = e?.number
                    const renderResult = () => {
                        if (postNumber.includes("*")) {
                            return <span className=""><Loading sizeDigit={postNumber.length} className="loading-draw w-1-vw"/></span>
                        } else if (postNumber.includes("?")) {
                            return <span className="text-post-sc"><Random sizeDigit={postNumber.length}
                                                                          className="draw-style-a mx-1"/></span>
                        } else return <span
                            className="number-sc"
                            id="jack-port"
                            data-num={postNumber}>{postNumber}</span>
                    };

                    return(
                        <div key={i} className="row m-3 rounded-sc bg-sc">
                            <div className="col-2 text-center bg-danger d-flex justify-content-center align-items-center post-rounded-sc">
                                <span className="post-code-sc">{SCPostCode[e.postCode]}</span>
                            </div>
                            <div className="col-10 text-center d-flex justify-content-center align-items-center">
                                {renderResult()}
                            </div>
                        </div>
                    );
                })
            }

        </div>
    </div>
};

export default ResultLiveSC;
