import React from "react";
import {getDatePrint} from "../../../../constant/dateUtils";

const PrintSixD58 = React.forwardRef(({...props}, ref) => {
    const {object, printDigit, className} = props;
    const isValidNum = (num) => (num.includes("*") || num.includes("?")) ? "-" : num;

    const renderPrints = () => {
        let prints = [];
        for (let i = 0; i < printDigit; i++) {
            prints.push(
                <table key={i} className="mb-3">
                    <tbody>
                    <tr>
                        <td colSpan="4" style={{fontSize: 20}} className="f-print-head">លទ្ធផលឆ្នោតមហាលាភសម្បត្តិ 6D
                        </td>
                    </tr>
                    <tr>
                        <td colSpan="4">{getDatePrint(object.drawAt)}</td>
                    </tr>

                    <tr>
                        <td colSpan={4} style={{letterSpacing: 8, fontSize: 25}}>{isValidNum(object.results[0][0])}</td>
                    </tr>
                    {
                        i === 7 && <tr>
                            <td style={{border: 0, marginBottom: 10, fontSize: 10}}>-</td>
                        </tr>
                    }
                    </tbody>
                </table>
            )
        }
        return prints;
    };

    return (
        <div ref={ref}>
            <div id="print-leap" className={className ?? "print58"}>
                {
                    renderPrints()
                }
            </div>
        </div>
    )
});

export default PrintSixD58;
