import React from "react";
import Layout from "../layout";
import 'react-day-picker/lib/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "../assets/css/custom.css"
import "../assets/css/header.css"
import "../assets/css/footer.css"
import "../assets/css/media.css"
import "../assets/css/slider.css"
import "../assets/css/result.css"
import "../assets/css/product.css"
import "../assets/css/menu.css"
import "../assets/css/popup.css"
import 'react-calendar/dist/Calendar.css';

import fontAwesomeIcon from "../utility/icons/fontawesome";
import {library} from "@fortawesome/fontawesome-svg-core";

library.add(fontAwesomeIcon);

const App = () => <Layout/>;
export default App;
