import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import moment from "moment";
import "moment/min/locales.min";
import App from "./App/App";
import { BrowserRouter } from "react-router-dom";
import store from "./redux";
import i18n from "./utility/locales/i18n";
import { I18nextProvider } from "react-i18next";

window.moment = moment;

const root = document.getElementById("root");

ReactDOM.render(
  <I18nextProvider i18n={i18n}>
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>
  </I18nextProvider>,
  root
);

