import React, {useState} from "react";
import {Calendar} from "react-calendar";
import OurMedia from "./ourMedia";
import {withTranslation} from "react-i18next";
import {getDateDef} from "../../../utility/dateUtils";

const RightSide = ({...props}) => {
    const {t, location: {search}} = props;
    const [value, onChange] = useState(new Date());
    const filterByDate = search.replaceAll("?search=", "");
    const date = search === "" ? value : new Date(filterByDate);

    const onClickDay = (value) => {
        props.history.push(`?search=${getDateDef(value)}`)
    };


    const getMinDate = () => {
      return   new Date("2005-01-01")
    };

    return <div id="calendar" className="col-sm-3 side-content p-0" style={{overflowX: "hidden"}}>
        <div className="custom-card">
            <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:lotto_history")}</h5>
        </div>
        <Calendar className="w-100"
                  value={date}
                  showNeighboringMonth={false}
                  calendarType="US"
                  maxDate={new Date()}
                  minDate={getMinDate()}
                  next2Label={null}
                  prev2Label={null}
                  onChange={onChange}
                  onClickDay={onClickDay}
        />
        <OurMedia/>
        <div className="card mb-1 mt-2 shadow-sm" id="hot-promotion">
            <div className="card-product custom-card m-0">
                <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:hot_promotion")}</h5>
            </div>
            <div className="card-body p-0">
                <img src={require("../../../assets/images/banner/right-banner.jpg")} alt=""/>
            </div>
        </div>
    </div>
};

export default withTranslation()(RightSide);
