import {combineReducers} from "redux";
import result6d from "./result6d";
import result5d from "./result5d";
import draw5 from "./draw5d";
import draw6 from "./draw6d";

const resultReducer = combineReducers({
    result5d,
    result6d,
    draw5,
    draw6
});

export default resultReducer;
