import React from 'react';
import {Switch, Route, BrowserRouter} from 'react-router-dom';
import routes from "../routes";
import Index from "../views/leap";

const Layout = () => {
    const menu = routes.map((route, index) => {
        return (route.component) ? (
            <Route
                key={index}
                path={route.path}
                exact={route.exact}
                name={route.name}
                component={route.component}/>
        ) : null;
    });

    return (
        <BrowserRouter>
            <Switch>
                {menu}
                <Route path="/" component={Index}/>
            </Switch>
        </BrowserRouter>
    );
};

export default Layout;
