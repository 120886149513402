import React from "react";

const MainAboutUs = () => {
    return (
        <div className="row" id="about-us">
            <div className="w-100 card-product mt-2 mb-2 mr-0 pr-0">
                <div className="p-5">
                    <div className="row">
                        <div className="col-md-7">
                            <h4 className="pb-3 align-left f-100 f-t-90"> ទំនាក់ទំនង </h4>
                            <div>
                                <div className="icon-block pb-3">
                                    <h4 className="h-contact align-left f-t-45">
                                        ពួកយើងរីករាយ សម្រាប់ការទំនាក់ទំនងរបស់លោកអ្នក
                                    </h4>
                                </div>
                                <div className="icon-contacts pb-3">
                                    <h5 className="r-contact f-t-30">
                                        រួចរាល់សម្រាប់ការសហការ
                                    </h5>
                                    <p className="tel-contact f-t-30">ទូរស័ព្ទ: 023 999 157 <br/>
                                        សារអេឡិចត្រូនិច: info@leapmorhasombathlotto.com</p>
                                </div>
                            </div>
                            <div data-form-type="formoid">
                                <div className="mb-2" data-form-alert="" hidden=""> អរគុណសម្រាប់ការទំនាក់ទំនង!</div>
                                <form className="block mbr-form"
                                      data-form-title="Mobirise Form">
                                    <input type="hidden"
                                           name="email"
                                           data-form-email="true"
                                           value=""
                                           data-form-field="Email"
                                    />
                                    <div className="row">
                                        <div className="col-md-6 multi-horizontal" data-for="name">
                                            <input type="text"
                                                   className="form-control input f-t-30"
                                                   name="name"
                                                   data-form-field="ឈ្មោះ"
                                                   placeholder="ឈ្មោះរបស់អ្នក" required=""
                                                   id="name-form4-k"
                                            />
                                        </div>
                                        <div className="col-md-6 multi-horizontal" data-for="phone">
                                            <input type="text"
                                                   className="form-control input f-t-30"
                                                   name="phone"
                                                   data-form-field="ទូរស័ព្ទ"
                                                   placeholder="លេខទូរស័ព្ទ"
                                                   required=""
                                                   id="phone-form4-k"/>
                                        </div>
                                        <div className="col-md-12" data-for="email">
                                            <input type="text" className="form-control input f-t-30" name="email"
                                                   data-form-field="សារអេឡិចត្រូនិច" placeholder="សារអេឡិចត្រូនិច"
                                                   required="" id="email-form4-k"/>
                                        </div>
                                        <div className="col-md-12" data-for="message">
                                                <textarea className="form-control input f-t-30" name="message" rows="3"
                                                          data-form-field="សារ" placeholder="សាររបស់អ្នក"
                                                          style={{resize: "none", id: "message-form4-k"}}/>
                                        </div>
                                        <div className="input-group-btn col-md-12 text-center"
                                             style={{marginTop: "10px"}}>
                                            <button type="submit"
                                                    className="btn btn-primary btn-form display-4 f-t-30">ផ្ញើសារឥឡូវ
                                            </button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="col-md-5">
                            <iframe className="w-100 h-100" title="adds"
                                    src="https://maps.google.com/maps?q=Orkid%C3%A9%20-%20The%20Royal%20Condominium&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                    frameBorder="0"/>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MainAboutUs;
