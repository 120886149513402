import React from "react";
import {withTranslation} from "react-i18next";
import Active from "../../../results/active";
import Waiting from "../../../results/wating";
import {getDateDisplay} from "../../../../utility/dateUtils";

const ResultActiveSc = (props) => {

    const {result, t} = props;
    const waiting = result.status === "WAITING";

    const renderImg = (sizeDigit, className) => {
        if (waiting) {
            return <Waiting src={require("../../../../assets/images/sc/logo.png")} sizeDigit={sizeDigit} className={className}/>
        } else return <Active  src={require("../../../../assets/images/sc/Loading-SC.gif")} sizeDigit={sizeDigit} className={className}/>
    };

    return <div id="result-show" className="animate-slide-up">
        <div className="card-product prod-width p-0">
            <div className="row col-sm-12 m-0 p-0">
                <div className="col-sm-12 bg-c-blue text-center text-yellow py-2" align="left">
                    <span
                        className="fw-bold head-result-date">{getDateDisplay(result.drawDate)} {t("labels:shift")} : {result.drawTime}</span>
                </div>
            </div>
            <hr className="m-0 border-dark"/>
            <div className="text-center post-code-sc p-lr-vw">
                {
                    ["ក", "ខ", "គ", "ឃ", "ង"].map((e, i)=>{
                        return(
                            <div key={i} className="row m-3 rounded-sc" style={{backgroundColor: "#f1f1f1"}}>
                                <div className="col-2 text-center bg-danger d-flex justify-content-center align-items-center post-rounded-sc">
                                    <span className="" style={{fontSize: "3.38vw", color: "yellow"}}>{e}</span>
                                </div>
                                <div className="col-10 text-center d-flex justify-content-center align-items-center">
                                    {
                                        renderImg(5, "img-waiting-post-sc")
                                    }
                                </div>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    </div>
};

export default (withTranslation()(ResultActiveSc));
