import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import {connect} from "react-redux";
import {fetchDrawStatus, fetchResults, resultSocket} from "../../redux/actions/resultActions";
import {withTranslation} from "react-i18next";
import Body from "../../components/qr-results/body/body";

const QrCodeResultPage = (props) => {
    const {type} = props;
    return (
        <MainTable isMenu={false} isAnnouncement={false} isActionSlider={false}>
            <Body  {...props} type={type}/>
        </MainTable>
    )
};

const mapStateToProps = state => ({
    results: state.result,
    resultMt: state.resultMt,
    resultTn: state.resultTn,
    resultVn1: state.resultVn1
});

export default connect(
    mapStateToProps,
    {
        fetchResults,
        fetchDrawStatus,
        resultSocket
    }
)(withTranslation()(QrCodeResultPage));
