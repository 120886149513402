import _ from "lodash";

export const digits = [
    {label: "Normal", value: 0},
    {label: "2D", value: -2},
    {label: "3D", value: -3},
    {label: "4D", value: -4},
];

export const onMouseEnter = (type, e, activeDigit) => {
    let doc = document.getElementsByClassName(`pop-able-number-${type}`);
    _.forEach(doc, (item) => {
        let num = item.attributes.getNamedItem("data-num").value;
        if (activeDigit < 0) {
            num = num.slice(activeDigit);
        }
        let twoDigit = num.slice(-2);
        let classNames = "highlight bg-green";
        if (twoDigit.includes(e)) {
            if (twoDigit.charAt(0) === twoDigit.charAt(1)) {
                classNames = "highlight bg-dark-green";
            } else if (twoDigit.charAt(1) === e.toString()) {
                classNames = "highlight bg-light-blue";

            }
            const replaceHtml = `<span>${num.slice(0, -2)}<span class="${classNames}">${twoDigit}</span></span>`;
            item.innerHTML = replaceHtml;
        }
    })


};

export const onMouseLeave = (type, activeDigit) => {
    let doc = document.getElementsByClassName(`pop-able-number-${type}`);
    _.forEach(doc, (item) => {
        let num = item.attributes.getNamedItem("data-num").value;
        if (activeDigit < 0) {
            num = num.slice(activeDigit);
        }
        item.innerHTML = `${num}`;
    })
};

export const onChangeDigits = ( type, digit = 0, activeDigit, setActiveDigit) => {
    if (digit === activeDigit) {
        digit = 0;
    }
    setActiveDigit(digit);
    let doc = document.getElementsByClassName(`pop-able-number-${type}`);
    _.forEach(doc, (item) => {
        const num = item.attributes.getNamedItem("data-num").value;
        let number = '';
        if (digit < 0) {
            number = num.slice(digit);
        } else {
            number = num;
        }
        item.innerHTML = `${number}`;
    })
};

export const posts = ["B", "C", "D", "F", "I", "N"];
export const sorts = [0, 2, 4, 1, 3, 5];

export const onChangeDigitsMt = (indexResult,digit = 0,activeDigit,setActiveDigit) => {
    if (digit === activeDigit) {
        digit = 0;
    }
    setActiveDigit(digit);
    let doc = document.getElementsByClassName(`pop-able-number-${indexResult}`);
    _.forEach(doc, (item) => {
        const num = item.attributes.getNamedItem("data-num").value;
        let number = '';
        if (digit < 0) {
            number = num.slice(digit);
        } else {
            number = num;
        }
        item.innerHTML = `${number}`;
    })
};

export     const onMouseEnterMt = (indexResult,e,activeDigit) => {
    let doc = document.getElementsByClassName(`pop-able-number-${indexResult}`);
    _.forEach(doc, (item) => {
        let num = item.attributes.getNamedItem("data-num").value;
        if (activeDigit < 0) {
            num = num.slice(activeDigit);
        }
        let twoDigit = num.slice(-2);
        let classNames = "highlight bg-green";
        if (twoDigit.includes(e)) {
            if (twoDigit.charAt(0) === twoDigit.charAt(1)) {
                classNames = "highlight bg-dark-green";
            } else if (twoDigit.charAt(1) === e.toString()) {
                classNames = "highlight bg-light-blue";

            }
            const replaceHtml = `<div>${num.slice(0, -2)}<span class="${classNames}">${twoDigit}</span></div>`;
            item.innerHTML = replaceHtml;
        }
    })
};

export const onMouseLeaveMt = (indexResult,activeDigit) => {
    let doc = document.getElementsByClassName(`pop-able-number-${indexResult}`);
    _.forEach(doc, (item) => {
        let num = item.attributes.getNamedItem("data-num").value;
        if (activeDigit < 0) {
            num = num.slice(activeDigit);
        }
        item.innerHTML = `${num}`;
    })
};
