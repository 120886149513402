import { DRAW_RESULT_VN1 } from "./types";

const init = {
    data: {}
};
const draw = (state = init, { type, payload }) => {
    switch (type) {
        case DRAW_RESULT_VN1:
            return {
                ...state,
                isLoading: false,
                data: payload
            };
        default:
            return state;
    }
}
export default draw;
