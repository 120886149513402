import {applyMiddleware, compose, createStore} from "redux";
import rootReducer from './reducers/rootReducer';
import thunk from "redux-thunk";

const middlewares = [thunk];
const composeEnhancers = window.REDUX_DEVTOOLS_EXTENSION_COMPOSE || compose;

const store = createStore(
    rootReducer, {},
    composeEnhancers(applyMiddleware(...middlewares))
);

export default store;
