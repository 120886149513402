import React from "react"
import {faAngleDown} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, useLocation} from "react-router-dom";
import {menus} from "./menus";

const Menu = (props) => {
    const {t} = props;
    const location = useLocation();
    const {pathname} = location;

    return (
        <div>
            <nav>
                <ul className="exo-menu" id="navmenu">
                    {
                        menus.map((e, i) => {
                            if (!e.children) {
                                return <li key={i}><Link className={pathname === e.url ? "active" : ""}
                                                         to={e.url}><FontAwesomeIcon
                                    icon={e.icon}/> {t(e.title)}</Link>
                                </li>
                            }

                            let urls = e.children.map(e => e.url);
                            return <li key={i} className="drop-down">
                                <Link to="#"
                                      className={urls.includes(pathname) ? "active" : ""}>
                                    <i><FontAwesomeIcon icon={e.icon}/></i> {t(e.title)} <FontAwesomeIcon
                                    icon={faAngleDown} className="align-middle mb-1"/></Link>
                                <ul className="drop-down-ul animated fadeIn">
                                    {e.children.map((p, j) => {
                                        return <li key={j} className="flyout-right"><Link
                                            className=""
                                            to={p.url}>{t(p.title)}</Link></li>
                                    })}

                                </ul>
                            </li>
                        })
                    }
                </ul>
            </nav>
            <div className="menu-toggle"><i className="fa fa-bars" aria-hidden="true"/>
            </div>
        </div>
    )
}
export default Menu;
