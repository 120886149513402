import React from "react"
import left6D from "../../../assets/images/banner/left-6D.jpg";
import active from "../../../assets/images/smallicon/active.gif"
import awarded from "../../../assets/images/smallicon/awarded.png"
import waiting from "../../../assets/images/smallicon/waiting.gif"
import {withTranslation} from "react-i18next"
import {connect} from "react-redux";
import _ from "lodash";
import {getTime} from "../../../utility/dateUtils";

const LeftSide = ({t, drawStatus: {data}, ...props}) => {

    const RenderStatus = ({status}) => {
        switch (status) {
            case "DRAWING" : {
                return <img className="mb-1 w-14" src={active} alt="DRAWING"/>
            }
            case "AWARDED" : {
                return <img className="mb-1 w-10" src={awarded} alt="AWARDED"/>
            }
            default : {
                return <img className="mb-1 w-14" src={waiting} alt="WAITING"/>
            }
        }
    };


    return <div className="col-sm-2 p-0">

        {
            !_.isEmpty(data) && Object.entries(data).map((e, i) => {
                return <div key={i} className="card mb-1 shadow-sm" id="live-report">
                    {i === 0 && <div className="custom-card">
                        <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:live_result")}</h5>
                    </div>}
                    <div className="custom-card bg-c-blue text-white">
                        <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:".concat(e[0].toUpperCase()))}</h5>
                    </div>

                    <div className="card-body card-product mb-0">
                        {
                            e[1]?.map((item, i) => {
                                return <ul key={i} className="list-unstyled">
                                    <li><span className="font-w-700 fw-vw">{getTime(item.drawAt)}</span> &nbsp;
                                        <RenderStatus status={item.status}/>
                                    </li>
                                </ul>
                            })
                        }
                    </div>
                </div>
            })
        }
        {/*<div className="card mb-1 shadow-sm" id="left-banner">*/}
        {/*    <div className="custom-card m-0">*/}
        {/*        <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:lotto_history")}</h5>*/}
        {/*    </div>*/}
        {/*    <div className="card-body p-0">*/}
        {/*        <a href="/#">*/}
        {/*            <img src={bannerLeft} alt=""/>*/}
        {/*        </a>*/}
        {/*    </div>*/}
        {/*</div>*/}
        {/*<div className="btnDownload card-product pt-2 mb-1">*/}
        {/*    <p className="f-t-28">{t("labels:download_app")}</p>*/}
        {/*    <a href="https://drive.google.com/file/d/1UF8bKDaEJRJoNWSHG97ReJQRb4TbfHi_/view?usp=sharing">*/}
        {/*        <img src={playStoreDownload} alt="" className="responsive img-fluid"/>*/}
        {/*    </a>*/}
        {/*</div>*/}
        <div className="card mb-1 shadow-sm" id="left-banner">
            <div className="custom-card m-0">
                <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:hot_promotion")}</h5>
            </div>
            <div className="card-body p-0">
                <a href="/#">
                    <img src={left6D} alt=""/>
                </a>
            </div>
        </div>
    </div>
};

const mapStateToProps = state => ({
    drawStatus: state.drawStatus,
});

export default connect(
    mapStateToProps,
    {}
)(withTranslation()(LeftSide));
