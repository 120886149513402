import React, {useState} from "react";
import LeapResultBody from "./leapResultBody";
import RenderMt from "./mtResultBody";
import DayPickerInput from "react-day-picker/DayPickerInput";
import {formatDate, parseDate} from "react-day-picker/moment";
import {getDateDisplay} from "../../../utility/dateUtils";
import {getDateDef} from "../../../constant/dateUtils";

const MidSide = (props) => {
    const {
        results: {result6d, draw6},
        t,
        resultMt,
        type,
        resultTn,
        resultVn1,
        location: {search}
    } = props;
    const [value, onChange] = useState(new Date());
    const filterByDate = search.replaceAll("?search=", "");
    const date = search === "" ? value : new Date(filterByDate);
    const drawResult = !draw6.isPopular ? {data: {}} : draw6;
    const drawResultVn = (draw) => !draw.isPopular ? {} : draw;

    const onChangeDay = (value) => {
        onChange(value)
        props.history.push(`?search=${getDateDef(value)}`)
    };

    return <div className="col-sm-12 shift-result px-2" style={{height: "100%"}}>
        <div className="d-flex align-items-center justify-content-center head-menu">
            <label className="m-0 font-w-700 text-yellow fs-result-vn-1">ជ្រើសរើស កាលបរិច្ឆេទ</label>
            <DayPickerInput
                inputProps={{
                    className: "form-control bg-transparent my-3 ml-3 fs-result-vn p-0 text-center mh-day font-weight-bold",
                    style: {
                        width: "20vw",
                        color: "#fefd00"
                    },
                    input: {
                        color: "#fefd00"
                    }
                }}
                dayPickerProps={{
                    disabledDays: {
                        after: new Date(),
                    },
                }}
                value={getDateDisplay(date)}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={t("labels:date")}
                format="DD / MM / YYYY"
                onDayChange={date => onChangeDay(date)}
            />
        </div>
        {/*{(type === 5 || !type) && <LeapResultBody*/}
        {/*    isQr={true}*/}
        {/*    type={5}*/}
        {/*    drawResult={draw5}*/}
        {/*    result={result5d}*/}
        {/*    t={t}/>}*/}
        {(type === 6 || !type) && <LeapResultBody
            isQr={true}
            type={6}
            drawResult={drawResult}
            result={result6d}
            t={t}/>}
        {!type && <RenderMt
            id="qr_result"
            results={resultMt.result}
            draw={drawResultVn(resultMt.draw.data)}
            type="mt"/>}
        {!type && <RenderMt
            id="qr_result"
            results={resultTn.result}
            draw={drawResultVn(resultTn.draw.data)}
            type="tn"/>}
        {!type && <RenderMt
            id="qr_result"
            results={resultVn1.result}
            draw={drawResultVn(resultVn1.draw.data)}
            type="vn1"/>}
    </div>
};

export default (MidSide);
