import React from 'react';
import {getDateDisplay, getTimeN} from "../../../utility/dateUtils";
import Loading from "../loading";
import Random from "../random";
import _ from "lodash";

const DayFormMC = ({items, t, isQr = false, id, isLive = false}) => {
    const lists = _.chunk(items.results, 2);

    const getTdValue = (value, className) => {
        if (value?.includes("*")) {
            return <Loading style={{width: "1.5vw"}} sizeDigit={value?.length}/>
        }
        if (value?.includes("?")) {
            return <Random
                sizeDigit={value?.length}
                className={`${isLive ? "draw-style-mt-live" : `draw-style-mt ${isQr ? "font-w-500 fs-qr-s-num" : "fs-qr-23"}`}`}
            />
        }
        return <span className={className}>{value}</span>
    }

    return (
        <>
            <div className="mid_content text-center">
                <div className="table_result">
                    <div className="result_header">
                        <div className={`result_date date_time ${isQr ? "fs-result-vn p-result-head-vn" : ""}`}>
                            <span>{getDateDisplay(items.drawAt)} - {t("labels:shift")} {getTimeN(items.drawAt)} {t("labels:minutes")}</span>
                        </div>
                    </div>
                    <table width="100%" className="table border" id={id ?? ""}>
                        <tbody>
                        {
                            lists.map((e, i) => {
                                return <tr key={i}>
                                    {
                                        e.map((p, j) => {
                                            return <td key={j}>
                                                <table className="table">
                                                    <tbody>
                                                    <tr>
                                                        <td style={{width: `${isQr ? "15vw" : "8vw"}`}}
                                                            className={`td-number-vn post-vn border-td border-left-0 bg-post-${p.postCode} ${p.postCode === "I" ? "f-tnr" : ""} ${isQr ? "fs-qr-b-num-vn" : "fs-qr-num-vn"}`}
                                                           >{p.postCode}</td>
                                                        <td className={`${j % 2 === 0 ? "" : "border-right-0"} td-number-vn d-flex justify-content-center border-td border-top-0 num-vn text-post-${p.postCode} ${isQr ? "fs-qr-num" : "fs-qr-num-vn"}`}>
                                                            <div
                                                                className={`d-flex align-items-center justify-content-center ${(isQr || !isLive) ? "" : "result-box-2d"}`}>
                                                                {getTdValue(p.fiveD, (isQr || !isLive) ? "" : "num-space")}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </td>
                                        })
                                    }
                                </tr>
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
};

export default DayFormMC;
