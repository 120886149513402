import moment from "moment";
import _ from "lodash";

export const getDateTime = date =>
    moment(date).format("DD-MM-YYYY hh:mm A");

export const getDateTimeDef = date => {
    return moment(date).format("YYYY-MM-DD hh:mm A")
};

export const getDate = date =>
    moment(date).format("DD-MM-YYYY");

export const getDateF = date =>
    moment(date).format("DD/MM/YYYY");

export const getDateFRange = (date1, date2) => {
    if (date1 === date2)
        return moment(date1).format("DD/MM/YYYY");
    return moment(date1).format("DD/MM/YYYY") + " - " + moment(date2).format("DD/MM/YYYY")
};

export const getDateDef = date =>
    moment(date).format("YYYY-MM-DD");

export const getTime = date =>
    moment(date).format("hh:mm A");

export const getTimeN = date =>
    moment(date).format("hh:mm");

export const getTime24H = date =>
    moment(date).format("HH:mm:ss");

export const getTimeFull = date =>
    moment(date).format("hh:mm:ss A");

export const getDateTimeFull = date =>
    moment(date).format("DD-MM-YYYY hh:mm:ss");

export const getDateTimeFullDef = date =>
    moment(date).format("YYYY-MM-DD HH:mm:ss");


export const getDateTimeHH = date =>
    moment(date).format("YYYY-MM-DD HH:mm:ss");


export const dateToDateTime = date =>
    date.concat(" 00:00:00");


export const getLogin = date =>
    moment(date).fromNow();

export const getDateDisplay = date =>
    moment(date).format("DD / MM / YYYY");

export const getDateS = date => {
    return moment(date).format("DD / MM / YYYY")
};

export const getDateTimeS = date => {
    return moment(date).format("DD/MM/YYYY hh:mm A")
};
export const format12h = time => {
    let times = _.split(time, ':', 3);
    let hours = times[0];
    let minutes = times[1];
    let ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? '' + minutes : minutes;
    return hours + ':' + minutes + ' ' + ampm;
};

export const getDay = date => moment(date).format("dddd");

export const getDayTh = date => moment(date).format("ddd");

export const getTimeHH = date => {
    return moment(date).format("HH")
};

export const getMinute = date => {
    return moment(date).format("mm")
};

export const getDateSlash = date => {
    return moment(date).format("DD/MM/YYYY")
};
