// import external modules
import {
  faBitcoin,
  faEthereum,
  faCloudsmith,
  faAsymmetrik,
  faFacebook,
  faTwitter,
  faLinkedinIn,
  faGooglePlusG,
  faMixcloud,
  faSoundcloud,
  faCloudversify,
  faFacebookF,
} from "@fortawesome/free-brands-svg-icons"
import {
  faCog,
  faCircle,
  faCaretDown,
  faCaretUp,
  faFileImage,
  faUser,
  faHeart,
  faDownload,
  faHome,
  faShare,
  faComments,
  faMailBulk,
  faBullseye,
  faHandPaper,
  faDatabase,
  faDollarSign,
  faTags,
  faExchangeAlt,
  faCalendarCheck,
  faLightbulb,
  faFolderOpen,
  faUsers,
  faShoppingCart,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faCube,
  faTag,
  faRandom,
  faCloud,
  faSyncAlt,
  faLevelDownAlt,
  faLevelUpAlt,
  faBars,
  faPencilAlt,
  faCheck,
  faEnvelope,
  faLock,
  faSearch,
  faCogs,
  faShareAlt,
  faFont,
  faMobileAlt,
  faChartLine,
  faListOl,
  faListUl,
  faThLarge,
  faTrash,
  faTimes,
  faPlus,
  faAdjust,
  faCalendarAlt,
  faUpload,
  faUserPlus,
  faThermometerHalf,
  faEllipsisV,
  faUserSecret,
  faTicketAlt,
  faAnchor,
  faDesktop,
  faBriefcase,
  faFileWord,
  faFilePowerpoint,
  faQuestion,
} from "@fortawesome/free-solid-svg-icons"
import {
  faBell,
  faFilePdf,
  faFileArchive,
  faFileCode,
  faStar,
  faEye,
  faMoneyBillAlt,
  faPaperPlane,
  faClock,
  faEdit,
  faCreditCard,
  faFileExcel,
} from "@fortawesome/free-regular-svg-icons"

const fontAwesomeIcon = [
  faBell,
  faCog,
  faCircle,
  faBitcoin,
  faEthereum,
  faCloudsmith,
  faAsymmetrik,
  faCaretDown,
  faCaretUp,
  faFilePdf,
  faFileImage,
  faFileArchive,
  faFileCode,
  faUser,
  faHeart,
  faDownload,
  faHome,
  faShare,
  faStar,
  faComments,
  faMailBulk,
  faFacebook,
  faTwitter,
  faLinkedinIn,
  faGooglePlusG,
  faEye,
  faBullseye,
  faHandPaper,
  faMoneyBillAlt,
  faDatabase,
  faDollarSign,
  faTags,
  faExchangeAlt,
  faCalendarCheck,
  faPaperPlane,
  faLightbulb,
  faFolderOpen,
  faUsers,
  faShoppingCart,
  faLongArrowAltUp,
  faLongArrowAltDown,
  faCube,
  faTag,
  faRandom,
  faCloud,
  faMixcloud,
  faSoundcloud,
  faCloudversify,
  faSyncAlt,
  faLevelDownAlt,
  faLevelUpAlt,
  faBars,
  faPencilAlt,
  faCheck,
  faFacebookF,
  faEnvelope,
  faLock,
  faSearch,
  faCogs,
  faShareAlt,
  faFont,
  faMobileAlt,
  faClock,
  faChartLine,
  faListOl,
  faListUl,
  faThLarge,
  faEdit,
  faTrash,
  faTimes,
  faPlus,
  faAdjust,
  faCalendarAlt,
  faCreditCard,
  faUpload,
  faUserPlus,
  faThermometerHalf,
  faEllipsisV,
  faUserSecret,
  faTicketAlt,
  faAnchor,
  faDesktop,
  faBriefcase,
  faFileWord,
  faFilePowerpoint,
  faFileExcel,
  faQuestion
]

export default fontAwesomeIcon
