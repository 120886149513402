import {FETCH_RESULT, LIST_RESULT6} from "./types";

const init = {
    isLoading: false,
    list: [],
    drawTime: []
};
const result6d = (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RESULT:
            return {
                ...state,
                isLoading: true
            };
        case LIST_RESULT6:
            return {
                ...state,
                isLoading: false,
                list: payload.data.draw,
                drawTime: payload.data.todayDrawStatus
            };
        default:
            return state;
    }

};

export default result6d;

