import React from "react";
import active from "../../assets/images/loading_ball.gif";

const Active = (props) => {

    const {sizeDigit, className, src} = props;
    const loads = [];
    for (let i = 0; i < sizeDigit; i++) {
        loads.push(<img alt="" className={className} key={i} src={src ?? active}/>);
    }
    return loads;
};

export default Active;
