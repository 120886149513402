import React, {useEffect} from "react";
import MainTable from "../../../components/mainpage/mainTable";
import {withTranslation} from "react-i18next";
import {connect} from "react-redux";
import {fetchResults} from "../../../redux/actions/resultActions";
import PrintA4 from "../../../components/results/prints/leap/printA4";
import LeapPrint from "../../../components/results/prints/leap/LeapPrint";

const PrintLeapResults = ({...props}) => {
    const {fetchResults, match:{params:{date}}, results:{list}} = props;

    useEffect(() => {
        fetchResults({filterByDate:date})
    }, [fetchResults, date]);

    return <MainTable>
        <div className="mt-3">
            <PrintA4 list={list} {...props}/>
            <hr/>
            <LeapPrint list={list} {...props}/>
        </div>
    </MainTable>
};


const mapStateToProps = state => ({
    results: state.result.result,
});

export default connect(
    mapStateToProps, {
        fetchResults
    }
)(withTranslation()(PrintLeapResults));