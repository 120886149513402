import React from "react";
import BannerLive from "../../assets/images/banner/banner-live.png"
import LottoLogo from "../../assets/images/lotto_logo_low.png"
import {getDate} from "../../utility/dateUtils";
import Result_MorhaTep from "../../assets/images/Result_MorhaTep.jpg";
import Result_TinhChneas from "../../assets/images/Result_TinhChneas.jpg";
import Result_MorhaChneas from "../../assets/images/Result_MorhaChneas.jpg";
import Result_SabayChneas from "../../assets/images/Result_SabayChneas.png";

const BodyLive = ({...props}) => {
    const {t, type} = props;

    const renderBanner = () =>{
        switch (type){
            case "mt": return <img className="img-fluid" src={Result_MorhaTep} alt=""/>;
            case "tn": return <img className="img-fluid" src={Result_TinhChneas} alt=""/>;
            case "mc": return <img className="img-fluid" src={Result_MorhaChneas} alt=""/>;
            case "sc": return <img className="img-fluid" src={Result_SabayChneas} alt=""/>
            default: return <img className="img-fluid" src={BannerLive} alt=""/>;
        }
    }

    return <div className="col-sm-8 shift-result h-100 px-2">
        <div className={`lottery-header-img ${type === "sc" ? "" : "lottery-card-header"}`}>
            {
                renderBanner()
            }
        </div>
        <div className={`col p-2 pt-2 ${type === "sc" ? "" : "header-date-homepage"}`}>
            <span
                className="fw-bold f-mn span-head font-weight-bold home-result-date-live f-t-70">{t("labels:date")} {getDate()}</span>
        </div>
        <div className="print6D">
            <div className="tab-content mt-3 text-center">
                <img className="img-fluid w-65" src={LottoLogo} alt=""/>
            </div>
        </div>
    </div>
};

export default BodyLive;
