import React, {useEffect} from "react";
import LeftSide from "./leftSide";
import RightSide from "./rightSide";
import MidSide from "./midSide";


const Body = ({...props}) => {
    const {fetchResults, location: {search}, resultSocket, type,fetchDrawStatus} = props;
    const date = search.replaceAll("?search=", "");
    const filterByDate = date === "" ? null : date;


    useEffect(() => {
        let params = {
            filterByDate: filterByDate
        }
        let isFetch = false;
        const getResult = () => {
            if (!isFetch) {
                fetchResults(params);
                fetchDrawStatus();
                isFetch = true
            }
        };
        fetchResults(params);
        fetchDrawStatus();
        resultSocket(getResult);
    }, [fetchResults, filterByDate, resultSocket,fetchDrawStatus]);


    return <div className="row pt-2 pb-3">
        <LeftSide/>
        <MidSide {...props} type={type}/>
        <RightSide {...props}/>
    </div>
};

export default Body;
