import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import Body from "../../components/mainpage/body/body";
import {connect} from "react-redux";
import {fetchDrawStatus, fetchResults, resultSocket} from "../../redux/actions/resultActions";
import {withTranslation} from "react-i18next";

const MainPage = (props) => {
    const {type} = props;
    return (
        <MainTable>
            <Body  {...props} type={type}/>
        </MainTable>
    )
};

const mapStateToProps = state => ({
    results: state.result,
    resultMt: state.resultMt,
    resultTn:state.resultTn,
    resultVn1:state.resultVn1,
    resultSc: state.resultSc.result,
    drawSc: state.resultSc.draw
});

export default connect(
    mapStateToProps,
    {
        fetchResults,
        fetchDrawStatus,
        resultSocket
    }
)(withTranslation()(MainPage));
