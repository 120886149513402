import React from "react";
import Loading from "../../results/loading";
import Random from "../../results/random";
import BannerLive from "../../../assets/images/banner/banner-live.png";
import {getDate} from "../../../utility/dateUtils";

const ResultLive = ({...props}) => {

    const {t, result} = props;
    const RenderPostA = () => {
        let postA = result.results[0][0];
        if (postA.includes("*")) {
            return <span className="align-middle"><Loading sizeDigit={postA.length} className="loading-draw"/></span>
        } else if (postA.includes("?")) {
            return <span className="align-middle"><Random sizeDigit={postA.length}
                                                          className="draw-style-a text-post-A"/></span>
        } else return <span className="big-result f-result-num align-middle text-center text-post-A pop-able-number"
                            id="jack-port" data-num={postA}>{postA}</span>
    };

    return <div className="col-sm-8 px-2">
        <div className="card-product mt-0 mb-0 align-center border-bottom-0" id="post-a">
            <div className="lottery-card-header lottery-header-img">
                <img className="img-fluid" src={BannerLive} alt=""/>
            </div>
            <div className="col p-2 pt-2 header-date-result">
            <span
                className="fw-bold f-mn span-head font-weight-bold home-result-date-live f-t-70">{t("labels:date")} {getDate(result.drawAt)}</span>
            </div>

        </div>

        <div className="card-product prod-width text-white">
            <div className="row col-sm-12 m-0 p-0">
                <div className="col-sm-12 row m-0 p-0" align="left">
                    <div className="col-sm-12 bg-c-blue text-center py-2 text-yellow" align="left">
                        <span style={{fontSize: "1.8vw", paddingLeft: "16px"}}
                              className="fw-bold head-result-date">{t("labels:shift")} {result.drawTime}</span>
                    </div>
                </div>
            </div>
            <hr className="m-0"/>

            <div className="row post-a m-0" id="post-a">
                <div className="col-2 p-0 card-border-right text-center post-a-vertical-middle">
                    <p className="align-middle f-result-live bg-post-A">រង្វាន់ធំ</p>
                </div>
                <div className="col-10 text-center p-post-a big-result-loading">
                <span
                    className="result-highlight big-result align-middle text-red-c f-tnr">A</span>
                    <span className="m-1 result-highlight big-result align-middle ml-2 mr-2 text-post-A"> = </span>
                    <RenderPostA/>
                </div>
            </div>
            <hr className="m-0"/>
            <div className="row p-0 m-0">
                <div className="col-2 card-border-right text-center lo-vertical-middle">
                    <p className="f-result-live">រង្វាន់ <br/>​លួងចិត្ត</p>
                </div>
                <div className="col-10 mt-3 row mb-3">
                    {
                        result.results[1].map((item, i) => {
                            if (item.includes("*")) {
                                return <div key={i} className="col-sm-3 text-center p-0 d-flex justify-content-center">
                                    <div className="result-highlight lo-result" key={i}><Loading
                                        sizeDigit={item.length}
                                        className="lo-loading-live"/></div>
                                </div>
                            }
                            if (item.includes("?")) {
                                return (<div key={i} className="col-sm-3 text-center p-0 d-flex justify-content-center">
                                    <span className="result-highlight lo-result" key={i}><Random
                                        sizeDigit={item.length}
                                        className="draw-style"/></span>
                                </div>)
                            }
                            return <div key={i} className="col-sm-3 text-center p-0 d-flex justify-content-center">
                                       <div className="result-highlight lo-result pop-able-number"
                                             data-length="5"
                                             data-num={item}>{item}</div>

                            </div>
                        })
                    }
                </div>
            </div>
            <hr className=" m-0"/>
            <div className="row post-special d-flex m-0" id="post-spec">
                <div className="col-2 p-0 m-0 card-border-right text-center post-vertical-middle pr-0 bg-post-F">
                    <p className="f-result-live">រង្វាន់ <br/> ពិសេស</p>
                </div>
                <div className="col-10 p-0">
                    <div className="row m-0">
                        {
                            ["B", "F", "C", "I", "D", "N"].map((item, i) => {
                                const renderPostNum = () => {
                                    let post = result.results[2][i];
                                    if (post.includes("*")) {
                                        return <span className="align-middle" key={i}>
                                                    <Loading
                                                        sizeDigit={post.length}
                                                        className="loading-draw"/>
                                               </span>
                                    } else if (post.includes("?")) {
                                        return <span className="align-middle" key={i}>
                                                    <Random sizeDigit={post.length}
                                                            className="result-highlight spacial-result draw-spacial-result text-post-F"/>
                                               </span>
                                    } else return <span key={i}
                                                        className="result-highlight align-middle spacial-result-live text-post-F pop-able-number"
                                                        data-length="6"
                                                        data-num={post}>{post}</span>
                                };
                                return (
                                    <div key={i}
                                         className={`col-6 p-0 pr-0 ${i < 4 ? "card-border-bottom" : ""}`}>
                                        <div
                                            className={`d-flex justify-content-center align-items-center spacial-result-loading ${i % 2 !== 1 ? "card-border-right" : ""}`}>
                                            <span
                                                className={`mt-2 result-highlight spacial-result  text-red-c mb-0 align-middle ${item === "I" ? "f-tnr" : ""}`}><div className="post-live">{item}</div></span>
                                            <span
                                                className="m-1 mr-ep result-highlight spacial-result text-blue-c align-middle text-post-F"> = </span>
                                            {
                                                renderPostNum()
                                            }
                                        </div>
                                    </div>)
                            })
                        }
                    </div>
                </div>

            </div>
        </div>
    </div>
};

export default ResultLive;
