import {FETCH_RESULT_MT, LIST_RESULT_MT} from "./types";

const init = {
    isLoading: false,
    list: {},
    filter: {}
};
const result =  (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RESULT_MT:
            return {
                ...state,
                isLoading: true,
                filter: payload
            };
        case LIST_RESULT_MT:
            return {
                ...state,
                isLoading: false,
                list: payload.data
            };
        default:
            return state;
    }

}
export default result;
