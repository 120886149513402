import React from "react";
import waiting from "../../assets/images/lotto_logo_low.png";

const Waiting = (props) => {

    const {sizeDigit, className, src} = props;
    const loads = [];
    for (let i = 0; i < sizeDigit; i++) {
        loads.push(<img alt="" className={className} key={i} src={src ?? waiting}/>);
    }
    return loads;
};

export default Waiting;
