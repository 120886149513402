import {FETCH_RESULT_SC, LIST_RESULT_SC} from "./types";

const init = {
    isLoading: false,
    list: [],
    drawTime: []
};
const result = (state = init, {type, payload}) => {
    switch (type) {
        case FETCH_RESULT_SC:
            return {
                ...state,
                isLoading: true
            };
        case LIST_RESULT_SC:
            return {
                ...state,
                isLoading: false,
                list: payload.data.draw,
                drawTime: payload.data.todayDrawStatus
            };
        default:
            return state;
    }

};

export default result;

