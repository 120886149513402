import moment from "moment";

export const getDateTime = date => {
    return moment(date).format("DD-MM-YYYY hh:mm A")
};
export const getDate = date => {
    return moment(date).format("DD-MM-YYYY")
};
export const getDateDef = date => {
    return moment(date).format("YYYY-MM-DD")
};
export const getTime = date => {
    return moment(date).format("hh:mm A")
};
export const getTimeN = date => {
    return moment(date).format("hh:mm")
};
export const getTimeFull = date => {
    return moment(date).format("hh:mm:ss A")
};
export const getDateTimeFull = date => {
    return moment(date).format("DD-MM-YYYY hh:mm:ss")
};
export const getDateTimeFullDef = date => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};

export const getDatePrint = date => {
    return moment(date).format("YYYY-MM-DD hh:mm A")
};

export const getDateTimeHH = date => {
    return moment(date).format("YYYY-MM-DD HH:mm:ss")
};

export const dateToDateTime = date => {
    return date.concat(" 00:00:00")
};

export const getLogin = date => {
    return moment(date).fromNow()
};

export const getYear = date => {
    return moment(date).format("YYYY")
};