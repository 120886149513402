import React from "react";
import {withTranslation} from "react-i18next";
import {faClock} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {getDateTimeFullDef} from "../../../constant/dateUtils";
import {titles} from "../../../constant/General";

const OurMedia = ({t}) => {
    return (
        <div className="card mb-1 mt-2 shadow-sm blog-card" id="winner-banner">
            <div className="custom-card mb-0">
                <h5 className="my-0 fw-normal text-center pb-2 f-t-28">{t("labels:our_media")}</h5>
            </div>

            <div className="p-0" id="style-6">
                {
                    Array(4).fill(0).map((item, i) => {
                        return (
                            <div key={i}>
                                <a className="row p-1" href="/" target="_blank">
                                    <div className="col-sm-6">
                                        <img alt="random"
                                             src={require("../../../assets/images/media/".concat(`${i}.jpg`))}/>
                                    </div>
                                    <div className="col-sm-6">
                                        <h6 className="sub_title">{titles[i]}</h6>
                                        <span className="timestamp card-clock"><FontAwesomeIcon
                                            icon={faClock}/>{getDateTimeFullDef()}</span>
                                    </div>
                                </a>
                                {
                                    i !== 3 && <hr className="my-2"/>
                                }
                            </div>
                        )
                    })
                }

            </div>
        </div>);
};

export default withTranslation()(OurMedia);
