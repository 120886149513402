import React from "react"
import MainTable from "../../components/mainpage/mainTable";
import MainMedia from "../../components/media/mainMedia";

const MainMediaPage = () => {
    return (
        <MainTable>
            <MainMedia/>
        </MainTable>
    )
};

export default MainMediaPage
