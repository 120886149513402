import React from "react";

const ProductImage = ({src}) => {

    return <div className="row">
        <div className="w-100 card-product mt-2 mr-0 pr-0">
            <img className="img-fluid w-100" src={src} alt="__"/>
        </div>
    </div>
};

export default ProductImage;
