import React from "react";
import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getLocale, locales } from "../../../utility/locales/locales";
import i18n from "../../../utility/locales/i18n";
import { Link } from "react-router-dom";

const TopHead = () => {
  const locale = getLocale(i18n.language);

  const list = () => {
    const list = [];
    locales.forEach((locale, key) => {
      list.push(
        <li key={key} className="f-p-26">
          <Link onClick={() => onChangLanguage(locale.lang)} to="#">
            <img src={locale.flag} alt="cambodia" />
            {locale.name}
          </Link>
        </li>
      );
    });
    return list;
  };

  const onChangLanguage = (lang) => {
    i18n.changeLanguage(lang);
  };

  return (
    <div className="row top-header">
      <div className="col-8 pt-1">
        <span className="f-p-20 f-t-28">info@leapmorhasombathlotto.com</span> |{" "}
        <meta name="format-detection" content="telephone=no" />
        <span className="f-p-20 f-t-28">Tel: 023 999 157</span>
      </div>
      <div className="col-4 pr-0">
        <ul className="flag-logo">
          <li className="drop-down lang-dropdown">
            <a href="/#">
              <img src={locale.flag} alt="cambodia" />
              {locale.name}
              <FontAwesomeIcon
                icon={faAngleDown}
                className="f-15 align-middle ml-2"
              />
            </a>
            <ul className="drop-down-ul fadeIn flag-logo">{list()}</ul>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default TopHead;
