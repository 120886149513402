import DrawTime from "./drawTime";
import ResultShow from "./resultShow";
import React, {useEffect, useState} from "react";
import ResultWaitingShow from "./resultActive";

const LeapResultBody = (props) => {
    const {result: {list}, drawResult: {data}, type} = props;
    const [drawTime, setDrawTime] = useState(null);
    useEffect(() => {
        if (list?.length > 0) {
            const getAwarded = list?.filter(e => e.status === "AWARDED");
            const lastAwarded = getAwarded?.length - 1;
            setDrawTime(list.filter(e => e.status === "AWARDED")[lastAwarded]?.drawTime)
        }
    }, [list]);

    const onClickTime = (time) => {
        setDrawTime(time)
    };


    return <div className="mb-result">
        <div className="card-product lottery-card-header">
            <img className="img-fluid" src={require(`../../../assets/images/Result_LMHSB_${type}.jpg`)} alt="3-min"/>
        </div>
        <div className={`col header-date-homepage  ${!(data.status === "ACTIVE") ? "p-2 pb-2" : ""}`}>

            {!(data.status === "ACTIVE") &&
            <DrawTime list={list} drawTime={drawTime} onClickTime={(time) => onClickTime(time)}/>}
        </div>
        {
            data.status === "ACTIVE" && <ResultShow type={type} drawTime={drawTime} result={data}/>
        }
        {
            list?.map((result, i) => {
                const isShow = result.drawTime === drawTime;
                return (
                    (!(data.status === "ACTIVE") && isShow) && <div key={i}>
                        {
                            result.status === "AWARDED" ?
                                <ResultShow key={i}
                                            type={type}
                                            drawTime={drawTime}
                                            {...props}
                                            result={result}

                                />

                                : <ResultWaitingShow key={i}
                                                     type={type}
                                                     drawTime={drawTime}
                                                     result={result}/>
                        }
                    </div>
                )
            })
        }
    </div>
}


export default LeapResultBody;
