import React from "react";
import Marquee from "react-fast-marquee/dist";
import { withTranslation } from "react-i18next";

const Announcement = ({t}) => {
    return<Marquee speed={100} gradient={false} style={{padding:"10px 0"}} className="text-uppercase text-white">
            {t("labels:welcome_to_lmhsb")}
        </Marquee>

};

export default withTranslation()(Announcement);
